import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { formatBold } from '../../../../utils'

export function LogoSuggestedActions({ venueUrlKey }: { venueUrlKey: string }) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s">
      <Text data-test="insights-logo-suggested-actions-navigate-settings">
        1.{' '}
        {formatMessage(insightsMessages.suggestedActionNavigatetoGeneralSettingsStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link
              to={nav.href(routes.manager2.settings.venue, {
                params: { venueKey: venueUrlKey },
                query: { activeTab: 'guestEmailSms' },
              })}
              target="_blank"
            >
              {chunks}
            </Link>
          ),
        })}
      </Text>
      <Text data-test="insights-logo-suggested-actions-upload">2. {formatMessage(insightsMessages.suggestedActionUploadLogo)}</Text>
      <Text data-test="insights-logo-suggested-actions-review-save">
        3.{' '}
        {formatMessage(insightsMessages.suggestedActionReviewEmailAndSave, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
    </VStack>
  )
}
