import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback, useRef, useEffect } from 'react'
import { useInsightsDashboardContext, useGetInsightSingleQuery } from '@sevenrooms/core/api'
import type { InsightDetail } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { SlideOut, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../locales'
import {
  OverallocatedDurationsDetail,
  AddCancellationFeeDetail,
  LogoDetail,
  POSEnabledDetail,
  PaymentEnabledDetail,
  RequestsEnabledDetail,
  FeedbackSummaryEmailEnabledDetail,
  TablesWithoutSeatingAreaDetail,
  ResSummaryEmailEnabledDetail,
  GoogleReserveEnabledDetail,
  AddEventDetail,
  AddUpgradesDetail,
  FeedbackSurveysDetail,
  LowUsersDetail,
  NoTableCombinationDetail,
  ReducePaidChannelDetail,
  LinkChecksDetail,
  PriorityAlertsDetail,
  MarketingAutomationsDetail,
  GoogleReserveAvailabilityDetail,
  UpgradeCoverageDetail,
  PacingCoverageDetail,
} from '../InsightDetailSlideoutContent'
import { InsightDetailSlideoutFooter } from './InsightDetailSlideoutFooter'

const getDetailComponent = (data: InsightDetail) => {
  switch (data.insight.insightTypeKey) {
    case 'over_allocated_durations':
      return <OverallocatedDurationsDetail data={data} />
    case 'add_cancellation_fee':
      return <AddCancellationFeeDetail data={data} />
    case 'feature_usage_users_created':
      return <LowUsersDetail data={data} />
    case 'feature_usage_pos_enabled':
      return <POSEnabledDetail data={data} />
    case 'feature_usage_payment_enabled':
      return <PaymentEnabledDetail data={data} />
    case 'feature_usage_google_reserve_enabled':
      return <GoogleReserveEnabledDetail data={data} />
    case 'feature_usage_requests_enabled':
      return <RequestsEnabledDetail data={data} />
    case 'feature_usage_daily_reservation_summary_enabled':
      return <ResSummaryEmailEnabledDetail data={data} />
    case 'feature_usage_feedback_alert_summary_enabled':
      return <FeedbackSummaryEmailEnabledDetail data={data} />
    case 'feature_usage_logo_enabled':
      return <LogoDetail data={data} />
    case 'tables_without_seating_area':
      return <TablesWithoutSeatingAreaDetail data={data} />
    case 'feature_usage_upcoming_event':
      return <AddEventDetail data={data} />
    case 'feature_usage_upgrades_created':
      return <AddUpgradesDetail data={data} />
    case 'feature_usage_feedback_surveys':
      return <FeedbackSurveysDetail data={data} />
    case 'no_table_combination':
      return <NoTableCombinationDetail data={data} />
    case 'reduce_paid_channel':
      return <ReducePaidChannelDetail data={data} />
    case 'feature_usage_low_pos_linkage':
      return <LinkChecksDetail data={data} />
    case 'feature_usage_priority_alerts':
      return <PriorityAlertsDetail data={data} />
    case 'feature_usage_marketing_automations':
      return <MarketingAutomationsDetail data={data} />
    case 'google_reserve_availability':
      return <GoogleReserveAvailabilityDetail data={data} />
    case 'access_rules_without_upgrades':
      return <UpgradeCoverageDetail data={data} />
    case 'pacing_coverage':
      return <PacingCoverageDetail data={data} />
    default:
      return null
  }
}

export function InsightDetailSlideout() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()
  const containerRef = useRef<HTMLDivElement>(null)
  const { slideoutOpen, setSlideoutOpen, setInsightId, insightId, setBrowseStatus } = useInsightsDashboardContext()
  const closeOut = useCallback(() => {
    setSlideoutOpen(false)
    setBrowseStatus(null)
    setInsightId(null)
  }, [setSlideoutOpen, setBrowseStatus, setInsightId])

  const { data, isError, isFetching } = useGetInsightSingleQuery(insightId ? { venueId: venue.id, insightId } : skipToken)

  if (isError && slideoutOpen) {
    notify({
      content: formatMessage(insightsMessages.errorFetchingInsight),
      type: 'error',
    })
    closeOut()
  }

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView(true)
    }
  }, [insightId])

  return (
    <SlideOut
      title=""
      show={slideoutOpen}
      onBeforeClose={closeOut}
      background="secondaryBackground"
      footer={<InsightDetailSlideoutFooter />}
      noTopPadding
    >
      {isFetching ? <Loader /> : <div ref={containerRef}>{data && getDetailComponent(data)}</div>}
    </SlideOut>
  )
}
