import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useDurationOptions } from '@sevenrooms/mgr-core/hooks/useDurationOptions'
import { Autocomplete } from '@sevenrooms/react-components/components/Autocomplete'
import { InputLabel } from '@sevenrooms/react-components/components/InputLabel'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { TextField } from '@sevenrooms/react-components/components/TextField'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useAvailabilityUrlParams } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'
import { filterAccessRuleDataByDate, hasDurationOverride, type AccessRuleInputProps } from './AccessRuleHelpers'

export function DurationInput({ accessRules }: Partial<AccessRuleInputProps>) {
  const { formatMessage } = useLocales()

  const [{ date, durationOverride }, setParams] = useAvailabilityUrlParams()
  const options = useDurationOptions()
  const iso8601 = date.toIso()

  const value = useMemo(() => getSelected(options, durationOverride), [durationOverride, options])
  const canSelectDuration = useMemo(() => {
    const availableAccessRules = filterAccessRuleDataByDate(accessRules ?? [], iso8601)
    return hasDurationOverride(availableAccessRules)
  }, [accessRules, iso8601])

  return (
    <Stack direction="column" width="220px" spacing={0}>
      <Stack alignItems="center" direction="row" spacing={1}>
        <InputLabel htmlFor="aqv-duration">
          <Typography>{formatMessage(availabilityDebuggerMessages.durationLabel)}</Typography>
        </InputLabel>
        <Tooltip disableFocusListener title={<Typography>{formatMessage(availabilityDebuggerMessages.durationTooltip)}</Typography>}>
          <InfoOutlinedIcon fontSize="small" color="action" />
        </Tooltip>
      </Stack>
      <Autocomplete
        fullWidth
        disabled={!canSelectDuration}
        id="aqv-duration"
        renderInput={params => <TextField {...params} />}
        options={options}
        value={value}
        onChange={(_event, selected, _reason) => setParams({ durationOverride: selected?.id })}
      />
    </Stack>
  )
}

function getSelected(options: ReturnType<typeof useDurationOptions>, selected?: number) {
  return options.filter(o => o.id === selected)[0] ?? null
}
