import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Box, type SideNavigationCategoryItem } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useInsightsLookerNavigationItems } from '@sevenrooms/mgr-core/hooks/useLookerNavigationItems'
import { insightsMessages } from '../locales'

const formatBadge = (chunks: string[]) => (
  <Box backgroundColor="errorHover" minWidth="18px" borderRadius="m" display="inline-block" ml="m" textAlign="center">
    <Text color="primaryBackground" fontSize="xs">
      {chunks}
    </Text>
  </Box>
)

export function useNavigationItems(): { navigationItems: Array<SideNavigationCategoryItem>; reportCount: number } {
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const venueKeyParams = { params: { venueKey: venue.urlKey } }

  const lookerNavigationItems = useInsightsLookerNavigationItems()
  const reportsItems = lookerNavigationItems.flatMap(x => x.items)

  const newInsightCount = window?.globalInit?.newInsightCount

  const menuText =
    newInsightCount > 0
      ? insightsMessages.insightsSideNavSubHeaderInsightsDashboard
      : insightsMessages.insightsSideNavSubHeaderInsightsDashboardNoCount

  const navigationItems = [
    {
      id: 'insights',
      title: formatMessage(insightsMessages.insightsSideNavHeaderInsights),
      allowed: true,
      collapsible: false,
      items: [
        {
          id: 'dashboard',
          title: formatMessage(menuText, {
            newInsightsCount: newInsightCount,

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            span: (chunks: string[]) => formatBadge(chunks),
          }),
          to: nav.href(routes.manager2.insights, venueKeyParams),
          items: [],
          reload: false,
          exact: true,
        },
      ],
    },
    {
      id: 'reports',
      title: formatMessage(insightsMessages.insightsSideNavHeaderReports),
      allowed: true,
      collapsible: false,
      items: reportsItems,
    },
  ]

  return { navigationItems, reportCount: reportsItems.length }
}
