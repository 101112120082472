import { useMemo, useState } from 'react'
import type { SeatingAreasCodesToTables } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Autocomplete } from '@sevenrooms/react-components/components/Autocomplete'
import { Checkbox } from '@sevenrooms/react-components/components/Checkbox'
import { FormControlLabel } from '@sevenrooms/react-components/components/FormControlLabel'
import { TextField } from '@sevenrooms/react-components/components/TextField'
import { useAvailabilityUrlParams } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'
import { StandardInput } from './StandardInput'
import type { AutocompleteOption } from './options'

export interface SeatingAreaInputProps {
  seatingAreas: SeatingAreasCodesToTables[]
}

export function SeatingAreaInput({ seatingAreas }: Partial<SeatingAreaInputProps>) {
  const { formatMessage } = useLocales()

  return (
    <StandardInput htmlFor="aqv-seating-area" label={formatMessage(availabilityDebuggerMessages.seatingAreaLabel)}>
      {seatingAreas != null ? <SeatingAreaInputEnabled seatingAreas={seatingAreas} /> : <SeatingAreaInputDisabled />}
    </StandardInput>
  )
}

function SeatingAreaInputEnabled({ seatingAreas: data }: SeatingAreaInputProps) {
  const { formatMessage } = useLocales()
  const [{ seatingAreaIds }, setParams] = useAvailabilityUrlParams()

  const [initiallySelected] = useState(seatingAreaIds)
  const options = useMemo(() => transformData(data), [data])
  const defaultValue = useMemo(() => getSelected(options, initiallySelected), [initiallySelected, options])

  return (
    <Autocomplete
      fullWidth
      id="aqv-seating-area"
      renderInput={params => (
        <TextField
          {...params}
          placeholder={seatingAreaIds.length === 0 ? formatMessage(availabilityDebuggerMessages.multiSelectPlaceholder) : undefined}
        />
      )}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props
        return (
          <li key={key} {...optionProps}>
            <FormControlLabel control={<Checkbox checked={selected} />} label={option.label} />
          </li>
        )
      }}
      multiple
      disableCloseOnSelect
      limitTags={1}
      options={options}
      defaultValue={defaultValue}
      onChange={(_event, selected, _reason) => setParams({ seatingAreaIds: selected?.map(s => s.value) })}
    />
  )
}

function SeatingAreaInputDisabled() {
  return <Autocomplete id="aqv-seating-area" fullWidth disabled options={[]} renderInput={params => <TextField {...params} />} />
}

function getSelected(options: AutocompleteOption[], selected?: string[]) {
  return options.filter(o => selected?.includes(o.value))
}

function transformData(data: SeatingAreasCodesToTables[]): AutocompleteOption[] {
  return data
    .filter(s => s.code !== '_none')
    .map(s => ({
      label: s.name,
      value: s.id,
    }))
}
