import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Autocomplete } from '@sevenrooms/react-components/components/Autocomplete'
import { Checkbox } from '@sevenrooms/react-components/components/Checkbox'
import { FormControlLabel } from '@sevenrooms/react-components/components/FormControlLabel'
import { TextField } from '@sevenrooms/react-components/components/TextField'
import { useAvailabilityUrlParams } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'
import {
  filterAccessRuleDataByDate,
  getSelectedAccessRuleOptions,
  transformAccessRuleDataToOptions,
  type AccessRuleInputProps,
} from './AccessRuleHelpers'
import { StandardInput } from './StandardInput'

export function AccessRuleInput({ accessRules }: Partial<AccessRuleInputProps>) {
  const { formatMessage } = useLocales()

  return (
    <StandardInput htmlFor="aqv-access-rules" label={formatMessage(availabilityDebuggerMessages.accessRulesLabel)}>
      {accessRules != null ? <AccessRuleInputEnabled accessRules={accessRules} /> : <AccessRuleInputDisabled />}
    </StandardInput>
  )
}

function AccessRuleInputEnabled({ accessRules: data }: AccessRuleInputProps) {
  const { formatMessage } = useLocales()
  const [{ accessPersistentIds, date }, setParams] = useAvailabilityUrlParams()
  const iso8601 = date.toIso()

  const options = useMemo(() => {
    const availableAccessRules = filterAccessRuleDataByDate(data, iso8601)
    return transformAccessRuleDataToOptions(availableAccessRules)
  }, [data, iso8601])
  const selectedOptions = useMemo(() => getSelectedAccessRuleOptions(options, accessPersistentIds), [accessPersistentIds, options])

  return (
    <Autocomplete
      fullWidth
      renderInput={params => (
        <TextField
          {...params}
          placeholder={accessPersistentIds.length === 0 ? formatMessage(availabilityDebuggerMessages.multiSelectPlaceholder) : undefined}
        />
      )}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props
        return (
          <li key={key} {...optionProps}>
            <FormControlLabel control={<Checkbox checked={selected} />} label={option.label} />
          </li>
        )
      }}
      id="aqv-access-rules"
      multiple
      disableCloseOnSelect
      limitTags={1}
      options={options}
      value={selectedOptions}
      onChange={(_event, selected, _reason) => {
        setParams({ accessPersistentIds: selected?.map(ar => ar.value) })
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  )
}

function AccessRuleInputDisabled() {
  return <Autocomplete id="aqv-access-rules" fullWidth multiple disabled options={[]} renderInput={params => <TextField {...params} />} />
}
