import { useMemo, useState } from 'react'
import { useGetInsightsQuery, useInsightsDashboardContext } from '@sevenrooms/core/api'
import type { Insight } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Loader, VStack, HStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../locales'
import { parseInsights, extractHighlights, variantColor } from '../../utils'
import { InsightHighlightRow } from '../InsightHighlightRow'
import { InsightTypeGroupSection } from '../InsightTypeGroupSection'
import { InsightUpsell } from '../InsightUpsell'
import { ProgressMeter } from './ProgressMeter'

function Expander({ type, count, onClick }: { type: string; count?: number; onClick: () => void }) {
  const { formatMessage } = useLocales()
  const text = type === 'remaining' ? formatMessage(insightsMessages.viewMoreInsights) : formatMessage(insightsMessages.priorityInsights)
  const statusMessage = type === 'remaining' ? `${count} ${formatMessage(insightsMessages.statusNewText)}` : 'Completed'
  const colorSetting = type === 'remaining' ? 'new' : 'optimized'
  const color = variantColor[colorSetting]

  return (
    <HStack
      onClick={onClick}
      cursor="pointer"
      p="m"
      alignItems="center"
      backgroundColor="primaryBackground"
      borderColor="dividerLines"
      borderRadius="s"
    >
      <Box borderRadius="s" pt="xs" pb="xs" pr="s" pl="s" backgroundColor={color}>
        <Text color="primaryBackground">{statusMessage}</Text>
      </Box>
      <Box ml="s" mr="auto">
        <SecondaryText>{text}</SecondaryText>
      </Box>
      <Box pr="s">
        <Icon name="VMSWeb-chevron-down" size="lg" color="darkFont" />
      </Box>
    </HStack>
  )
}

export function InsightsDashboard() {
  const { venue } = useVenueContext()
  const { data, isLoading, isError } = useGetInsightsQuery({ venueId: venue.id })
  const { formatMessage } = useLocales()

  const { setInsightVenueUsers } = useInsightsDashboardContext()
  const [expanded, setExpanded] = useState<boolean>(false)

  const fullAccess = data ? data.accessData.fullAccess : false

  const categories = useMemo(() => (data ? parseInsights(data) : []), [data])
  const highlightInsights = useMemo(() => (data ? extractHighlights(data.insights) : []), [data])
  const hasHighlightInsights = !!highlightInsights.length

  const highlightsRemaining = highlightInsights.filter(insight => insight.status === 'new')
  const incomplete = !!highlightsRemaining.length
  const insightsCompleted = highlightInsights.length - highlightsRemaining.length

  const nonHighlightInsightsCount = data?.insights.filter(insight => insight.status === 'new' && insight.featuredRank === null).length

  const highlightsDisplay: Array<Insight | null> = [...highlightInsights, null, null].slice(0, 3)

  const insightVenueUsers = data?.insightUsers
  setInsightVenueUsers(insightVenueUsers)

  useMemo(() => {
    if (isError) {
      notify({ content: formatMessage(insightsMessages.errorFetchingInsights), type: 'error' })
    }
  }, [isError, formatMessage])

  return (
    <Box pl="lm" pr="lm" mb="xxl" width="100%">
      {!isLoading && !fullAccess && !isError ? <InsightUpsell hiddenCount={data?.accessData.hiddenCount ?? 0} /> : null}
      <VStack spacing="lm" mt="lm">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {hasHighlightInsights && (
              <>
                {expanded || incomplete ? (
                  <HStack spacing="sm">
                    <VStack spacing="sm" width="100%">
                      {highlightsDisplay?.map((insight, index) => (
                        <InsightHighlightRow key={insight?.id || `null${index}`} highlightInsights={highlightInsights} insight={insight} />
                      ))}
                    </VStack>
                    <ProgressMeter
                      total={highlightInsights.length}
                      completed={insightsCompleted}
                      emptyColor="dividerLines"
                      completedColor="success"
                      interiorColor="secondaryBackground"
                    />
                  </HStack>
                ) : (
                  <Expander onClick={() => setExpanded(true)} type="completed" />
                )}
              </>
            )}
            {expanded || !incomplete || !hasHighlightInsights ? (
              <>
                {categories[0]?.insightTypeGroups?.map(group => (
                  <InsightTypeGroupSection
                    key={group.key}
                    insightTypeGroup={group.key}
                    insightTypes={group.insightTypes ?? []}
                    insightVenueUsers={insightVenueUsers ?? []}
                  />
                ))}
              </>
            ) : (
              <Expander onClick={() => setExpanded(true)} type="remaining" count={nonHighlightInsightsCount} />
            )}
          </>
        )}
      </VStack>
    </Box>
  )
}
