import type { ShiftSuggestedActions } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { useInsightUtils } from '../../../hooks'
import { insightsMessages } from '../../../locales'
import { formatBold } from '../../../utils'

export interface OverallocatedDurationsSuggestedActionsProps {
  venueUrlKey: string
  insightSuggestedActionData: ShiftSuggestedActions
}

export function OverallocatedDurationsSuggestedActions({
  venueUrlKey,
  insightSuggestedActionData,
}: OverallocatedDurationsSuggestedActionsProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { getFormattedHoursAndMinutes } = useInsightUtils()
  const { shiftName, stepsData } = insightSuggestedActionData
  const stepsDisplay = stepsData?.map(step => (
    <Text key={`${step.guestCount}-${step.duration}`} data-test={`overallocated-substep-${step.guestCount}-${step.duration}`}>
      {formatMessage(insightsMessages.overallocatedDurationsAdjustDurationStep, {
        guestCount: step.guestCount,
        duration: getFormattedHoursAndMinutes(step.duration),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: the type ParseMessage needs to be updated
        strong: (chunks: string[]) => formatBold(chunks),
      })}
    </Text>
  ))
  return (
    <VStack p="s s s xxs" spacing="s">
      <Text>
        1.{' '}
        <Link to={nav.href(routes.manager.manage.shifts, { params: { venueKey: venueUrlKey } })} target="_blank">
          {formatMessage(insightsMessages.suggestedActionNavigatetoShiftLinkText, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          })}
        </Link>
      </Text>
      <Text>
        2.{' '}
        {formatMessage(insightsMessages.suggestedActionEditShiftStep, {
          shiftName,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <VStack spacing="xxs">
        <Text>
          3.{' '}
          {formatMessage(insightsMessages.suggestedActionNavigatetoCapacitySettingsStep, {
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        <VStack pl="m">{stepsDisplay}</VStack>
      </VStack>
      <Text>
        4.{' '}
        {formatMessage(insightsMessages.suggestedActionSaveStep, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
    </VStack>
  )
}
