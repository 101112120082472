import React from 'react'
import type { AvailabilityCalendarResponse, AvailabilityCalendarPartySizeResponse } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Container } from '@sevenrooms/react-components/components/Container'
import { Grid } from '@sevenrooms/react-components/components/Grid'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import { availabilityDebuggerMessages } from '../../locales'
import { AvailabilityCalendarBlock } from './AvailabilityCalendarBlock'
import { AvailabilityCalendarContainer } from './AvailabilityCalendarContainer'
import { AvailabilityCalendarGridRow } from './AvailabilityCalendarGridRow'
import { GRID_ITEM_MIN_WIDTH, GRID_LABEL_ITEM_MIN_WIDTH } from './constants'

export function AvailabilityCalendarKey() {
  const theme = useTheme()
  const { formatMessage } = useLocales()

  return (
    <Stack
      direction="row"
      display="flex"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      fontWeight={theme.typography.fontWeightMedium}
      sx={{
        mb: theme.spacing(4),
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <AvailabilityCalendarBlock timeslot={{ isOpen: true, hasAvailability: true }} isLegend />
        <span>{formatMessage(availabilityDebuggerMessages.keyAvailable)}</span>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <AvailabilityCalendarBlock timeslot={{ isOpen: true, hasAvailability: false }} isLegend />
        <span>{formatMessage(availabilityDebuggerMessages.keyNotAvailable)}</span>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box>
          <AvailabilityCalendarBlock timeslot={{ isOpen: false, hasAvailability: false }} isLegend />
        </Box>
        <span>{formatMessage(availabilityDebuggerMessages.keyInactive)}</span>
      </Stack>
    </Stack>
  )
}

function AvailabilityCalendarHeaderRow({ startTime, endTime }: { startTime: TimeOnly; endTime: TimeOnly }) {
  const theme = useTheme()

  let hourSpan
  if (endTime.getInfo().hours < startTime.getInfo().hours) {
    hourSpan = 24 - startTime.getInfo().hours + endTime.getInfo().hours + 1
  } else {
    hourSpan = endTime.getInfo().hours - startTime.getInfo().hours + 1
  }

  return (
    <Grid container item spacing={1} wrap="nowrap" sx={{ mb: theme.spacing(1) }}>
      {Array.from({ length: hourSpan }, (_, i) => startTime.addMinutes(i * 60).formatSTime()).map(timeString => (
        <Grid item key={timeString} sx={{ minWidth: GRID_ITEM_MIN_WIDTH }}>
          <Box height="100%" display="flex" alignItems="center" justifyContent="left" fontWeight={theme.typography.fontWeightMedium}>
            <span>{timeString}</span>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export function AvailabilityCalendarPartySizeLabels({ availability }: { availability: AvailabilityCalendarPartySizeResponse[] }) {
  const theme = useTheme()
  const { formatMessage } = useLocales()

  return (
    <Stack pt="14px">
      {availability.map(({ partySize }) => (
        <Box
          key={`label-${partySize}`}
          height="22px"
          pt={theme.spacing(1)}
          sx={{ minWidth: GRID_LABEL_ITEM_MIN_WIDTH }}
          display="flex"
          alignItems="center"
          justifyContent="start"
          fontWeight={theme.typography.fontWeightMedium}
        >
          <span>
            {partySize === 1
              ? formatMessage(availabilityDebuggerMessages.partySizeOne)
              : formatMessage(availabilityDebuggerMessages.partySize, { partySize })}
          </span>
        </Box>
      ))}
    </Stack>
  )
}

export interface AvailabilityCalendarGridProps {
  data: AvailabilityCalendarResponse
  date: DateOnly
}

export function AvailabilityCalendarGrid({ data, date }: AvailabilityCalendarGridProps) {
  const theme = useTheme()

  const { availability } = data

  const timeslots = availability.flatMap(x => x.timeslots)
  const hasTimeslots = timeslots.length > 0
  const start = hasTimeslots ? timeslots.reduce((a, b) => (a.sortOrder < b.sortOrder ? a : b)) : null
  const end = hasTimeslots ? timeslots.reduce((a, b) => (a.sortOrder < b.sortOrder ? b : a)) : null

  const availabilitySorted = [...availability].sort(({ partySize }) => partySize)

  const rows = availabilitySorted.map(({ partySize, timeslots }) => (
    <AvailabilityCalendarGridRow key={`grid-row-${partySize}`} partySize={partySize} timeslots={timeslots} date={date} />
  ))

  return (
    <AvailabilityCalendarContainer>
      <AvailabilityCalendarKey />
      <Stack direction="row">
        <AvailabilityCalendarPartySizeLabels availability={availabilitySorted} />
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            pb: theme.spacing(3),
            overflowX: 'scroll',
          }}
        >
          {hasTimeslots && start && end ? (
            <Grid container>
              <AvailabilityCalendarHeaderRow startTime={start.time} endTime={end.time} />
              {rows}
            </Grid>
          ) : (
            <NoShiftsForDayMessage />
          )}
        </Container>
      </Stack>
    </AvailabilityCalendarContainer>
  )
}

function NoShiftsForDayMessage() {
  const theme = useTheme()
  const { formatMessage } = useLocales()
  return (
    <Box height="22px" pt={theme.spacing(1)} display="flex" alignItems="center" justifyContent="center">
      <span>{formatMessage(availabilityDebuggerMessages.noShiftsForDay)}</span>
    </Box>
  )
}
