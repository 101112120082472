import { useEffect, useMemo } from 'react'
import { useGetVenueStaticDataQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Route, Switch, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { NotificationContainer, type SideNavigationPageCalendar, type SideNavigationPageItem } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPage, useNavigationPermissionsContext, useUserContext, useVenueContext } from '@sevenrooms/mgr-core'
import { useNavigationCalendarContext } from '@sevenrooms/mgr-core/hooks/useNavigationCalendarContext'
import { OrdersManagement } from '../components/OrdersManagement'
import { ordersMessages } from '../locales/ordersMessages'

export interface OrdersProps {}

export function Orders() {
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const user = useUserContext()
  const permissions = useNavigationPermissionsContext()
  const { date, setDate, highlightedDates, setHighlightedDates } = useNavigationCalendarContext()
  const { data: venueStaticData } = useGetVenueStaticDataQuery({
    venueId: venue.id,
  })
  const calendarOptions: SideNavigationPageCalendar = {
    date,
    isDayHighlighted: (day: Date) => {
      const formatted = DateOnly.fromDate(day).toIso()
      return highlightedDates.includes(formatted)
    },
    onDateChange: (date: Date | null) => {
      const dateString = date ? DateOnly.fromDate(date).toIso() : ''
      nav.replace(routes.manager2.orders, { params: { venueKey: venue.urlKey }, query: { date: dateString } })
      setDate(date)
    },
  }

  useEffect(() => {
    if (venueStaticData?.ordersDates) {
      setHighlightedDates(venueStaticData.ordersDates)
    }
  }, [setHighlightedDates, venueStaticData])

  const sideNavigationItems = useMemo(() => {
    const items: SideNavigationPageItem[] = []

    if (venue && user) {
      const venueKeyParams = { params: { venueKey: venue?.urlKey } }

      const canAccessOrdering =
        venue.isSevenRoomsOrderingEnabled && permissions?.canAccessSettingsNav && permissions?.canManageRestrictedSettings

      if (canAccessOrdering) {
        items.push({
          id: 'orderingSite',
          title: formatMessage(ordersMessages.ordersQuickLinkOrderingSite),
          to: nav.href(routes.manager2.settings.ordering.sites, venueKeyParams),
          items: [],
          reload: true,
        })
        items.push({
          id: 'menuManagement',
          title: formatMessage(ordersMessages.ordersQuickLinkMenuManagement),
          to: nav.href(routes.manager2.settings.ordering.menus, venueKeyParams),
          items: [],
          reload: true,
        })
        if (venue.isSevenRoomsOrderingMiddleware && !permissions?.isOnboardingSpecialist) {
          items.push({
            id: 'eightySixProducts',
            title: formatMessage(ordersMessages.ordersQuickLinkEightySixProducts),
            to: nav.href(routes.manager2.settings.ordering.eightySixProduct, venueKeyParams),
            items: [],
            reload: true,
          })
        }
        if (!permissions.isOnboardingSpecialist) {
          items.push({
            id: 'checkoutSettings',
            title: formatMessage(ordersMessages.ordersQuickLinkCheckoutSettings),
            to: nav.href(routes.manager2.settings.ordering.checkout, venueKeyParams),
            items: [],
            reload: true,
          })
        }
        if (permissions.isOrderingSmsEnabled) {
          items.push({
            id: 'guestNotifications',
            title: formatMessage(ordersMessages.ordersQuickLinkGuestNotifications),
            to: nav.href(routes.manager2.settings.ordering.notificationSettings, venueKeyParams),
            items: [],
            reload: true,
          })
        }
        if (!permissions.isOnboardingSpecialist) {
          items.push({
            id: 'orderPacing',
            title: formatMessage(ordersMessages.ordersQuickLinkOrderPacing),
            to: nav.href(routes.manager2.settings.ordering.pacingRules, venueKeyParams),
            items: [],
            reload: true,
          })
          items.push({
            id: 'serviceFees',
            title: formatMessage(ordersMessages.ordersQuickLinkServiceFees),
            to: nav.href(routes.manager2.settings.ordering.serviceFees, venueKeyParams),
            items: [],
            reload: true,
          })
          if (permissions.canViewReporting) {
            const firstReport = venue.lookerFolders['Ordering Management']?.[0]
            if (firstReport) {
              items.push({
                id: 'ordersReporting',
                title: formatMessage(ordersMessages.ordersQuickLinkOrdersReporting),
                to: nav.href(routes.manager2.reporting.embed, {
                  params: { venueKey: venue.urlKey, type: firstReport.type, id: firstReport.id },
                }),
                items: [],
                reload: true,
              })
            }
          }
          items.push({
            id: 'roomNumbers',
            title: formatMessage(ordersMessages.ordersQuickLinkRoomNumbers),
            to: nav.href(routes.manager2.settings.ordering.roomNumbers, venueKeyParams),
            items: [],
            reload: true,
          })
        }
      }
      if (permissions?.canViewMarketing) {
        items.push({
          id: 'promoCodeManagement',
          title: formatMessage(ordersMessages.ordersQuickLinkPromoCodeManagement),
          to: nav.href(routes.manager2.marketing.promoCodesManagement, venueKeyParams),
          items: [],
          reload: true,
        })
      }

      if (!items.length) {
        return []
      }

      return [
        {
          id: 'links',
          title: formatMessage(ordersMessages.ordersQuickLinksTitle),
          to: nav.href(routes.manager2.orders, venueKeyParams),
          collapsible: false,
          items,
        },
      ]
    }

    return []
  }, [venue, user, permissions, formatMessage, nav])

  if (!venue) {
    return null
  }

  return (
    <SettingsPage showSideNavigation hideSearch hideVenueName calendar={calendarOptions} sideNavigationItems={sideNavigationItems}>
      <Switch>
        <Route path={routes.manager2.orders.path} component={OrdersManagement} />
      </Switch>
      <NotificationContainer />
    </SettingsPage>
  )
}
