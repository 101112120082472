import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text, List } from '@sevenrooms/core/ui-kit/typography'
import { insightsMessages } from '../../../../locales'
import { formatBold } from '../../../../utils'

export function MarketingAutomationsSuggestedActions({ venueUrlKey }: { venueUrlKey: string }) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-marketing-automations-suggested-actions">
      <List variant="ordered" spacing="s">
        <Text type="li" data-test="insights-marketing-automations-suggested-actions-navigate-automations">
          {formatMessage(insightsMessages.suggestedActionsNavigateAutomations, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            link: (chunks: string[]) => (
              <Link
                to={nav.href(routes.manager2.marketing.emailCenter.emails, {
                  params: { venueKey: venueUrlKey },
                })}
                target="_blank"
              >
                {chunks}
              </Link>
            ),
          })}
        </Text>
        <Text type="li" data-test="insights-marketing-automations-suggested-actions-review">
          {formatMessage(insightsMessages.suggestedActionsReviewDefaultCampaigns)}
        </Text>
        <Text type="li" data-test="insights-marketing-automations-suggested-actions-edit">
          {formatMessage(insightsMessages.suggestedActionsEditEmail, {
            icon: <Icon name="VMSWeb-more-vertical" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        <Text type="li" data-test="insights-marketing-automations-suggested-actions-turn-on">
          {formatMessage(insightsMessages.suggestedActionsTurnOnAutomatedEmail, {
            icon: <Icon name="VMSWeb-more-vertical" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            strong: (chunks: string[]) => formatBold(chunks),
          })}
        </Text>
        <Text type="li" data-test="insights-marketing-automations-suggested-actions-repeat">
          {formatMessage(insightsMessages.suggestedActionsRepeatCampaigns)}
        </Text>
      </List>
    </VStack>
  )
}
