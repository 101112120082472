import { FlatfileButton } from '@flatfile/react'
import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { onChangeVenue } from 'mgr/lib/actions/GlobalActions'
import { Desc, SubDesc, Contents, SubContainer } from 'mgr/pages/single-venue/settings/components/shared'
import TagMapping from 'mgr/pages/single-venue/settings/containers/TagMapping'
import { StyledVmsIconXS, VmsIcons } from 'svr/common/VmsIcons'
import ModalCover from 'svr/component-lib/Generic/Modals/ModalCover'
import RadioList from 'svr/component-lib/Generic/RadioButtons/RadioList'
import ContentLayout from 'svr/component-lib/Manager/Layout/Content'
import {
  getInitialData,
  uploadFlatFile,
  initializeSubmissionDialogue,
  closeSubmissionDialog,
  submitImport,
} from '../actions/ReservationAndClientImport'
import { Button, CheckboxGroup } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Spinner, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { VenuePrivilegeLevelEnum } from '@sevenrooms/core/domain'

const zIndexCss = css`
  z-index: 999;
  border-radius: 8px;
`

const Icon = styled(StyledVmsIconXS)`
  display: inline-block;
  color: #6a6a6a;
  ${props => props.customIconStyles};
`

const closeIconCss = css`
  font-size: 18px;
  color: #092238;
  cursor: pointer;
  padding-right: 2px;
`

const ModalBody = styled.div`
  width: 564px;
  border-radius: 5px;
  background-color: white;
  padding: 16px;
`
const ModalClose = styled.div`
  text-align: right;
`
const ModalContents = styled.div`
  padding: 0 2px 0 20px;
`

const ModalContent = styled.div`
  padding: 0px 0 20px 0;
  font-size: 16px;
`

const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 13px;
`

const ContainerForButtons = styled.div`
  margin-top: 20px;
`

class ReservationAndClientImport extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      customFields: [],
      customTagFields: [],
      dateFormat: '',
      futureTableAndSeatingAreaOverride: 'table',
      flatFileResults: null,
      prevImportType: props.importType,
      step: 1,
      tagsToMapping: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.importType !== state.prevImportType) {
      return {
        dateFormat: '',
        futureTableAndSeatingAreaOverride: 'table',
        prevImportType: props.importType,
        step: 1,
      }
    }
    return null
  }

  componentDidMount() {
    if (!this.shouldWeShowThisPage()) {
      window.location = '/'
    }
    this.props.getInitialData(this.props.venue.id, this.props.importType)
  }

  shouldWeShowThisPage() {
    return (
      this.props.user.is_superuser ||
      (this.props.venue.isFlatFileImportEnabled &&
        [VenuePrivilegeLevelEnum.MANAGER, VenuePrivilegeLevelEnum.SUPERUSER].includes(this.props.userDomain.venue_privilege_level))
    )
  }

  handleFlatFileDone = newFlatFileResults => {
    this.setState({
      customFields: _.chain(newFlatFileResults.$data).first().get('data.$custom').keys().value().sort(),
      flatFileResults: newFlatFileResults,
      step: 3,
    })
  }

  handleTagFieldsSelected = () => {
    this.setState(prevState => ({
      tagsToMapping: _.chain(prevState.flatFileResults.$data)
        .flatMapDeep(elem =>
          _.chain(elem.data.$custom)
            .pick(prevState.customTagFields)
            .pickBy(_.identity)
            .values()
            .map(tags => _.split(tags, ','))
            .value()
        )
        .map(tag => _.trim(tag))
        .uniq()
        .value(),
      step: 4,
    }))
    this.props.uploadFlatFile(this.state.flatFileResults)
  }

  submit = () => {
    const {
      importType,
      reservationAndClientImport: { blobstoreUploadKey, mappedTags, tagGroups },
      venue: { id: venueId },
    } = this.props
    const newTagGroupsToSubmit = _.filter(tagGroups, tagGroup => tagGroup.isNew)

    this.props.submitImport(
      venueId,
      importType,
      blobstoreUploadKey,
      this.state.customTagFields,
      mappedTags,
      newTagGroupsToSubmit,
      this.state.dateFormat,
      this.state.futureTableAndSeatingAreaOverride
    )
  }

  handleCopyImportId = () => {
    navigator.clipboard.writeText(this.props.reservationAndClientImport.importId)
  }

  handleClosePopUp = () => {
    this.props.closeSubmissionDialog()
    this.setState({
      dateFormat: '',
      futureTableAndSeatingAreaOverride: 'table',
      step: 1,
    })
  }

  handleOpenPopUp = () => {
    this.props.initializeSubmissionDialogue()
  }

  chooseDateFormat = value => {
    this.setState({
      dateFormat: value,
    })
  }

  chooseTableAndSeatingAreaPriority = value => {
    this.setState({
      futureTableAndSeatingAreaOverride: value,
    })
  }

  updateValidatorsForDateFields = (fields, dateFields, dateFormat) => {
    const updatedFields = _.cloneDeep(fields)
    const regexByDateFormat = {
      'dd.mm.yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[012])\\.\\d{4}$',
      'dd/mm/yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\/(0?[1-9]|1[012])\\/\\d{4}$',
      'dd-mm-yyyy': '^(0?[1-9]|[12][0-9]|3[01])\\-(0?[1-9]|1[012])\\-\\d{4}$',

      'yyyy.mm.dd': '^\\d{4}\\.(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])$',
      'yyyy/mm/dd': '^\\d{4}\\/(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])$',
      'yyyy-mm-dd': '^\\d{4}\\-(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$',

      'mm.dd.yyyy': '^(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])\\.\\d{4}$',
      'mm/dd/yyyy': '^(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])\\/\\d{4}$',
      'mm-dd-yyyy': '^(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])\\-\\d{4}$',

      'dd.mm': '^(0?[1-9]|[12][0-9]|3[01])\\.(0?[1-9]|1[012])$',
      'dd/mm': '^(0?[1-9]|[12][0-9]|3[01])\\/(0?[1-9]|1[012])$',
      'dd-mm': '^(0?[1-9]|[12][0-9]|3[01])\\-(0?[1-9]|1[012])$',

      'mm.dd': '^(0?[1-9]|1[012])\\.(0?[1-9]|[12][0-9]|3[01])$',
      'mm/dd': '^(0?[1-9]|1[012])\\/(0?[1-9]|[12][0-9]|3[01])$',
      'mm-dd': '^(0?[1-9]|1[012])\\-(0?[1-9]|[12][0-9]|3[01])$',
    }
    _.each(updatedFields, field => {
      if (dateFields.includes(field.key)) {
        const validator = {
          validate: 'regex_matches',
          regex: regexByDateFormat[dateFormat],
        }
        if (!field.validators) {
          // eslint-disable-next-line no-param-reassign
          field.validators = [validator]
        } else {
          const index = _.findIndex(field.validators, { validate: 'regex_matches' })
          if (index !== -1) {
            // eslint-disable-next-line no-param-reassign
            field.validators[index] = validator
          } else {
            field.validators.push(validator)
          }
        }
      }
    })
    return updatedFields
  }

  renderChoosingImportSettings() {
    return (
      <>
        <Desc>Step 1</Desc>
        <SubDesc>Please choose a date format</SubDesc>
        <RadioList
          field="dateFormat"
          selectedValue={this.state.dateFormat}
          options={
            this.props.importType === 'reservation'
              ? [
                  { value: 'yyyy.mm.dd', label: 'yyyy.mm.dd' },
                  { value: 'yyyy/mm/dd', label: 'yyyy/mm/dd' },
                  { value: 'yyyy-mm-dd', label: 'yyyy-mm-dd' },
                  { value: 'mm.dd.yyyy', label: 'mm.dd.yyyy' },
                  { value: 'mm/dd/yyyy', label: 'mm/dd/yyyy' },
                  { value: 'mm-dd-yyyy', label: 'mm-dd-yyyy' },
                  { value: 'dd.mm.yyyy', label: 'dd.mm.yyyy' },
                  { value: 'dd/mm/yyyy', label: 'dd/mm/yyyy' },
                  { value: 'dd-mm-yyyy', label: 'dd-mm-yyyy' },
                ]
              : [
                  { value: 'dd.mm', label: 'dd.mm' },
                  { value: 'dd/mm', label: 'dd/mm' },
                  { value: 'dd-mm', label: 'dd-mm' },
                  { value: 'mm.dd', label: 'mm.dd' },
                  { value: 'mm/dd', label: 'mm/dd' },
                  { value: 'mm-dd', label: 'mm-dd' },
                ]
          }
          onClick={this.chooseDateFormat}
        />
        {this.props.importType === 'reservation' ? (
          <>
            <br />
            <SubDesc>
              For future reservations, please specify how to prioritize Table and Seating Area if the provided table is not in the provided
              area.
            </SubDesc>
            <RadioList
              field="futureTableAndSeatingAreaOverride"
              selectedValue={this.state.futureTableAndSeatingAreaOverride}
              options={[
                { value: 'table', label: "Update the Seating Area to match the Table's actual location." },
                {
                  value: 'seating_area',
                  label: 'Remove the Table value and allow the system to auto-assign to a table within the provided Seating Area.',
                },
                { value: 'no_override', label: 'Allow the Table and Seating Area to be mismatched.' },
              ]}
              onClick={this.chooseTableAndSeatingAreaPriority}
            />
          </>
        ) : null}
      </>
    )
  }

  renderPreSettings() {
    if (this.state.step !== 1) {
      return null
    }
    return (
      <>
        {this.renderChoosingImportSettings()}
        <ContainerForButtons>
          <Button onClick={() => this.setState({ step: 2 })} disabled={!this.state.dateFormat}>
            Next
          </Button>
        </ContainerForButtons>
      </>
    )
  }

  renderFlatFileButton(title, updatedFields) {
    if (this.state.step !== 2) {
      return null
    }
    const flatFileSettings = {
      type: title,
      fields: updatedFields,
      allowCustom: true,
    }
    const flatFileCustomer = {
      companyId: this.props.reservationAndClientImport.companyId,
      companyName: this.props.reservationAndClientImport.companyName,
      email: this.props.reservationAndClientImport.email,
      name: this.props.reservationAndClientImport.name,
      userId: this.props.reservationAndClientImport.userId,
    }

    return (
      <>
        <Desc>Step 2</Desc>
        <SubDesc>{`Date format is '${this.state.dateFormat}'`}</SubDesc>
        <SubDesc>{`Please upload a file. Don't forget to map field from file with '${this.props.importType} tags' field`}</SubDesc>
        <FlatfileButton
          settings={flatFileSettings}
          licenseKey={this.props.reservationAndClientImport.licenseKey}
          customer={flatFileCustomer}
          onData={async flatFileResults => {
            this.handleFlatFileDone(flatFileResults)
          }}
          render={(importer, launch) => (
            <ContainerForButtons>
              <Button onClick={() => this.setState({ step: 1 })} style={{ marginRight: '20px' }}>
                Back
              </Button>
              <Button onClick={launch}>Import file</Button>
            </ContainerForButtons>
          )}
        />
      </>
    )
  }

  renderTagFieldSelection() {
    if (this.state.step !== 3) {
      return null
    }
    const customFieldOptions = _.map(this.state.customFields, field => ({ label: field, value: field }))
    return (
      <>
        <Desc>Step 3</Desc>
        <SubDesc>
          Select which custom columns from your file contain <b>TAGS</b> that you want to map to 7R tags.
        </SubDesc>
        <CheckboxGroup choices={customFieldOptions} onChange={customTagFields => this.setState({ customTagFields })} />
        <ContainerForButtons>
          <Button onClick={() => this.setState({ step: 2 })} style={{ marginRight: '20px' }}>
            Back
          </Button>
          <Button onClick={() => this.handleTagFieldsSelected()}>Map tags</Button>
        </ContainerForButtons>
      </>
    )
  }

  renderTagMapping() {
    if (this.state.step !== 4) {
      return null
    }
    return (
      <>
        <Desc>Step 4</Desc>
        <SubDesc>Map tags from file with 7R tags. You can create a new tag even with new category</SubDesc>
        <TagMapping tagsToMapping={this.state.tagsToMapping} tagGroups={this.props.reservationAndClientImport.tagGroups} />
        <Desc>Step 4</Desc>
        {!this.props.reservationAndClientImport.blobstoreUploadKey ? (
          <SubDesc>File is uploading, please wait..</SubDesc>
        ) : (
          <SubDesc>Click submit if you have mapped tags</SubDesc>
        )}
        <ContainerForButtons>
          <Button onClick={() => this.setState({ step: 3 })} style={{ marginRight: '20px' }}>
            Back
          </Button>
          <Button
            onClick={this.handleOpenPopUp}
            className="sr-start-import-flatfile"
            disabled={!this.props.reservationAndClientImport.blobstoreUploadKey}
          >
            Submit
          </Button>
        </ContainerForButtons>
      </>
    )
  }

  render() {
    const { venue, venues } = this.props
    if (!this.shouldWeShowThisPage() || !this.props.reservationAndClientImport.isInitialDataLoaded) {
      return null
    }
    const title = this.props.importType === 'reservation' ? 'Reservation import' : 'Client import'
    const updatedFields = this.updateValidatorsForDateFields(
      this.props.reservationAndClientImport.fields,
      this.props.reservationAndClientImport.dateFields,
      this.state.dateFormat
    )

    return (
      <ContentLayout title={title} venue={venue} venues={venues} onChangeVenue={this.props.onChangeVenue}>
        {this.props.reservationAndClientImport.isPopUpOpen && (
          <ModalCover customModalScreen={zIndexCss}>
            <ModalBody>
              <ModalClose>
                <Icon customIconStyles={closeIconCss} onClick={this.handleClosePopUp}>
                  {VmsIcons.Close}
                </Icon>
              </ModalClose>
              <ModalContents>
                <ModalHeader>Importing</ModalHeader>
                <ModalContent>
                  <VStack spacing="m">
                    <Box>
                      <Text>Importing your data may take some time. You will receive an email when the import is finished.</Text>
                    </Box>
                    {this.props.reservationAndClientImport.isSubmitting && (
                      <Box textAlign="center" width="100%">
                        <Spinner />
                      </Box>
                    )}
                    {!!this.props.reservationAndClientImport.importId && (
                      <>
                        <Box textAlign="center" width="100%">
                          <Text fontSize="s" fontWeight="bold">
                            Import ID: {this.props.reservationAndClientImport.importId}
                          </Text>
                        </Box>
                        <Box>
                          <Text>Please save this Import ID in case you need to undo the import.</Text>
                        </Box>
                      </>
                    )}
                  </VStack>
                </ModalContent>
                <HStack justifyContent="right" spacing="m">
                  {!this.props.reservationAndClientImport.isSubmitting && !this.props.reservationAndClientImport.importId ? (
                    <Button onClick={this.submit} className="sr-start-import-flatfile">
                      Submit
                    </Button>
                  ) : (
                    <>
                      <Button variant="secondary" onClick={this.handleClosePopUp}>
                        Close
                      </Button>
                      <Button onClick={this.handleCopyImportId} disabled={!this.props.reservationAndClientImport.importId}>
                        Copy Import ID
                      </Button>
                    </>
                  )}
                </HStack>
              </ModalContents>
            </ModalBody>
          </ModalCover>
        )}
        <Contents>
          <SubContainer>
            {this.renderPreSettings()}
            {this.renderFlatFileButton(title, updatedFields)}
            {this.renderTagFieldSelection()}
            {this.renderTagMapping()}
          </SubContainer>
        </Contents>
      </ContentLayout>
    )
  }
}

ReservationAndClientImport.propTypes = {
  venue: React.PropTypes.object.isRequired,
  venues: React.PropTypes.array.isRequired,
  reservationAndClientImport: React.PropTypes.object.isRequired,
  userDomain: React.PropTypes.object.isRequired,
  importType: React.PropTypes.string.isRequired,
  getInitialData: React.PropTypes.func,
  uploadFlatFile: React.PropTypes.func,
  initializeSubmissionDialogue: React.PropTypes.func,
  closeSubmissionDialog: React.PropTypes.func,
  submitImport: React.PropTypes.func,
  onChangeVenue: React.PropTypes.func,
}

const mapStateToProps = state => {
  const { venue, userDomain, user } = state.appState
  const venues = userDomain && userDomain.venues ? userDomain.venues : [venue]
  return {
    user,
    venue,
    venues,
    reservationAndClientImport: state.reservationAndClientImport,
    userDomain,
  }
}

const mapDispatchToProps = {
  onChangeVenue,
  getInitialData,
  uploadFlatFile,
  initializeSubmissionDialogue,
  closeSubmissionDialog,
  submitImport,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReservationAndClientImport)
