import ReactDOM, { render } from 'react-dom'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  VStack,
  Window,
  Root,
  WindowProvider,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { svrExport } from '@sevenrooms/core/utils'

interface WarningModalProps {
  header: string
  warnings: Array<{ id: string; info: Array<string> }>
  primaryButtonText: string
  secondaryButtonText: string
  handlePrimaryClick: () => void
  handleSecondaryClick: () => void
  handleCloseClick: () => void
}

function WarningModal({
  header,
  warnings,
  primaryButtonText,
  secondaryButtonText,
  handlePrimaryClick,
  handleSecondaryClick,
  handleCloseClick,
}: WarningModalProps) {
  return (
    <Window active>
      <Modal ariaLabel="Modal">
        <ModalHeader onClose={handleCloseClick}>
          <VStack spacing="s">
            <Status kind="warning">Warning</Status>
            <ModalTitle title={header} />
          </VStack>
        </ModalHeader>
        {warnings.map(warning => (
          <ModalBody key={`warning-${warning.id}`}>
            <Text>{warning.info.join(' | ')}</Text>
          </ModalBody>
        ))}
        <ModalFooter>
          <ModalActions>
            <Button variant="tertiary" onClick={handleCloseClick} data-test="sr-warning-modal-close">
              Go Back
            </Button>

            <Button variant="secondary" onClick={handleSecondaryClick} data-test="sr-warning-modal-secondary-button">
              {secondaryButtonText}
            </Button>
            {primaryButtonText && (
              <Button variant="primary" onClick={handlePrimaryClick} data-test="sr-warning-modal-primary-button">
                {primaryButtonText}
              </Button>
            )}
          </ModalActions>
        </ModalFooter>
      </Modal>
    </Window>
  )
}

export default WarningModal

export const mountWarningModal = (
  domId: string,
  header: string,
  warnings: Array<{ id: string; info: Array<string> }>,
  primaryButtonText: string,
  secondaryButtonText: string,
  handlePrimaryClick: (unmount: () => boolean) => void,
  handleSecondaryClick: (unmount: () => boolean) => void,
  handleCloseClick: (unmount: () => boolean) => void
) => {
  const container = document.getElementById(domId)
  const unmount = () => ReactDOM.unmountComponentAtNode(container as Element)
  render(
    <Root theme="vx">
      <WindowProvider>
        <WarningModal
          header={header}
          warnings={warnings}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          handlePrimaryClick={() => handlePrimaryClick && handlePrimaryClick(unmount)}
          handleSecondaryClick={() => handleSecondaryClick && handleSecondaryClick(unmount)}
          handleCloseClick={() => handleCloseClick && handleCloseClick(unmount)}
        />
      </WindowProvider>
    </Root>,
    container
  )
}
svrExport('Components', 'mountWarningModal', mountWarningModal)
