import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../../locales'
import { CREATE_EVENT_INSTRUCTIONS_URL } from '../../../../utils'

export function AddEventsSuggestedActions() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-add-events-suggested-actions">
      <Text data-test="insights-add-events-suggested-actions-navigate">
        1.{' '}
        <Link
          to={nav.href(routes.manager.events, {
            params: { venueKey: venue.urlKey },
          })}
          target="_blank"
          data-test="insights-add-events-suggested-actions-events-link"
        >
          {formatMessage(insightsMessages.suggestedActionsAddEventsNavigateStep, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          })}
        </Link>
      </Text>
      <Text data-test="insights-add-events-suggested-actions-config">
        2.{' '}
        {formatMessage(insightsMessages.suggestedActionsFollowInstructionsStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link
              to={CREATE_EVENT_INSTRUCTIONS_URL}
              target="_blank"
              data-test="insights-add-events-suggested-actions-event-instructions-link"
            >
              {chunks}
            </Link>
          ),
        })}
      </Text>
      <VStack spacing="s" pl="m">
        <Text data-test="insights-add-events-suggested-actions-create-event">
          a. {formatMessage(insightsMessages.suggestedActionsAddEventsCreateEventStep)}
        </Text>
        <Text data-test="insights-add-events-suggested-actions-add-inventory">
          b. {formatMessage(insightsMessages.suggestedActionsAddEventsAddInventoryStep)}
        </Text>
        <Text data-test="insights-add-events-suggested-actions-review">
          c.{' '}
          {formatMessage(insightsMessages.suggestedActionsAddEventsReviewStep, {
            icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: the type ParseMessage needs to be updated
            link: (chunks: string[]) => (
              <Link to={`/events/${venue.urlKey}`} target="_blank" data-test="insights-add-events-suggested-actions-event-widget-link">
                {chunks}
              </Link>
            ),
          })}
        </Text>
      </VStack>
    </VStack>
  )
}
