import React, { useMemo, useState } from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly, DateOnly } from '@sevenrooms/core/timepiece'
import { AccordionDetails } from '@sevenrooms/react-components/components/AccordionDetails'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { tablesMatchCategoryMessages } from '../../../locales'
import { AccordionContent } from './AccordionContent'
import { compareTableItems } from './tablesCategoryUtil'
import { getBlocksUrl, getReservationUrl } from './urlRedirectUtil'

interface TablesMatchCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  defaultExpanded?: boolean
  time: TimeOnly
  date: DateOnly
  venue: Venue
}

interface TablesMatchDetailsProps {
  reason: string
  reasons: AvailabilityDebuggerReason[]
  time: TimeOnly
  date: DateOnly
  venue: Venue
}

interface TablesRuleContentProps {
  formattedHeader: string
  formattedTableDetails: React.ReactNode
}

interface TablesMatchGroupedReasons {
  HARD_ASSIGNED_RESERVATIONS: AvailabilityDebuggerReason[]
  AUTO_ASSIGNED_RESERVATIONS: AvailabilityDebuggerReason[]
  START_TIME_BLOCK: AvailabilityDebuggerReason[]
  BLOCKED: AvailabilityDebuggerReason[]
}

function TablesRuleContent({ formattedHeader, formattedTableDetails }: TablesRuleContentProps) {
  return (
    <Box sx={{ '&:not(:last-child)': { pb: 2 } }}>
      <Typography fontWeight="medium">{formattedHeader}</Typography>
      {formattedTableDetails}
    </Box>
  )
}

function TablesMatchDetails({ reason, reasons, time, date, venue }: TablesMatchDetailsProps) {
  const { formatMessage } = useLocales()

  let formattedHeader: string
  let formattedTableDetails: JSX.Element[]

  switch (reason) {
    case 'HARD_ASSIGNED_RESERVATIONS':
      formattedHeader = formatMessage(tablesMatchCategoryMessages.mainDescriptionHeaderHardAssignedReservations, {
        count: reasons.length,
        numTables: reasons.length,
        time: time.formatSTime(),
      })
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesMatchCategoryMessages.mainDescriptionHardAssignedReservations, {
            table: r.data?.tableItemCode,
            a: (chunks: string[]) =>
              r.data?.shiftPersistentId && r.data.slotActualId ? (
                <Link href={getReservationUrl(venue, date, r.data?.shiftPersistentId, r.data?.slotActualId)} target="_blank">
                  {chunks}
                </Link>
              ) : (
                <>{chunks}</>
              ),
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'AUTO_ASSIGNED_RESERVATIONS':
      formattedHeader = formatMessage(tablesMatchCategoryMessages.mainDescriptionHeaderAutoAssignedReservations, {
        count: reasons.length,
        numTables: reasons.length,
        time: time.formatSTime(),
      })
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesMatchCategoryMessages.mainDescriptionAutoAssignedReservations, {
            table: r.data?.tableItemCode,
            a: (chunks: string[]) =>
              r.data?.shiftPersistentId && r.data.slotActualId ? (
                <Link href={getReservationUrl(venue, date, r.data?.shiftPersistentId, r.data?.slotActualId)} target="_blank">
                  {chunks}
                </Link>
              ) : (
                <>{chunks}</>
              ),
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'START_TIME_BLOCK':
      formattedHeader = formatMessage(tablesMatchCategoryMessages.mainDescriptionHeaderStartTimeBlock, {
        count: reasons.length,
        numTables: reasons.length,
        time: time.formatSTime(),
      })
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesMatchCategoryMessages.mainDescriptionStartTimeBlock, {
            table: r.data?.tableItemCode,
            blockName: r.data?.blockNames?.join(', '),
            a: (chunks: string[]) => (
              <Link href={getBlocksUrl(venue, date, r.data?.shiftCategory as string)} target="_blank">
                {chunks}
              </Link>
            ),
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    case 'BLOCKED':
      formattedHeader = formatMessage(tablesMatchCategoryMessages.mainDescriptionHeaderBlocked, {
        count: reasons.length,
        numTables: reasons.length,
        time: time.formatSTime(),
      })
      formattedTableDetails = reasons.map(r => (
        <Typography key={`${r.data?.tableItemCode}`} sx={{ pl: 1, listStyle: 'initial' }} component="li">
          {formatMessage(tablesMatchCategoryMessages.mainDescriptionBlocked, {
            table: r.data?.tableItemCode,
            blockName: r.data?.blockNames?.join(', '),
            a: (chunks: string[]) => (
              <Link href={getBlocksUrl(venue, date, r.data?.shiftCategory as string)} target="_blank">
                {chunks}
              </Link>
            ),
          })}
        </Typography>
      ))
      return <TablesRuleContent formattedHeader={formattedHeader} formattedTableDetails={formattedTableDetails} />
    default:
      return <></>
  }
}

function groupTablesByReason(reasons: AvailabilityDebuggerReason[]) {
  const groupedReasons: TablesMatchGroupedReasons = {
    HARD_ASSIGNED_RESERVATIONS: [],
    AUTO_ASSIGNED_RESERVATIONS: [],
    START_TIME_BLOCK: [],
    BLOCKED: [],
  }

  let numTablesAffected = 0

  // group table reasons together and de-dupe and table item code reasons
  reasons.sort(compareTableItems).forEach(r => {
    const reason = r.reason as keyof TablesMatchGroupedReasons
    if (reason in groupedReasons) {
      if (groupedReasons[reason].length > 0) {
        const tc = r.data?.tableItemCode
        if (!groupedReasons[reason].some(r => r.data?.tableItemCode === tc)) {
          groupedReasons[reason].push(r)
          numTablesAffected += 1
        }
      } else {
        groupedReasons[reason].push(r)
        numTablesAffected += 1
      }
    }
  })

  return { groupedReasons, numTablesAffected }
}

export function TablesMatchCategory({ reasons, defaultExpanded = true, time, date, venue }: TablesMatchCategoryProps) {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  const { formatMessage } = useLocales()
  const { groupedReasons, numTablesAffected } = useMemo(() => groupTablesByReason(reasons), [reasons])
  const headerMessage = formatMessage(tablesMatchCategoryMessages.accordianHeader, {
    count: numTablesAffected,
    numTables: numTablesAffected,
  })

  if (numTablesAffected === 0) {
    return <></>
  }

  return (
    <AccordionContent
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      headerMessage={headerMessage}
      dataTestId="tables-match-category-content"
      actionButtonEnabled
      actionButtonProps={{
        label: formatMessage(tablesMatchCategoryMessages.manageTablesButtonText),
        url: `/manager/${venue.urlKey}/manage/capacity/table/edit`,
      }}
    >
      <AccordionDetails id="tables-match-panel-content">
        {Object.entries(groupedReasons)
          .filter(([, reasons]) => reasons.length > 0)
          .map(([reason, reasons]) => (
            <TablesMatchDetails key={`${reason}`} reason={reason} reasons={reasons} time={time} date={date} venue={venue} />
          ))}
      </AccordionDetails>
    </AccordionContent>
  )
}
