import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { StyledVmsIconS, VmsIcons } from 'svr/common/VmsIcons'
import { copyToClipboard } from '@sevenrooms/core/utils'

const MenuContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 36px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0px 0px;
  width: 215px;
  background: ${props => props.theme.background};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 15px;
  white-space: nowrap;
  font-weight: normal;
  transition: opacity 0.3s;
  color: #0a0a0a;
  z-index: 1;

  a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
  }
`

const MenuOption = styled.div`
  height: 30px;
  width: 215px;
  display: flex;
  padding-left: ${props => props.theme.padding.medium};

  &:hover {
    color: ${props => props.theme.darkGrey};
    transition: color 0.3s;
  }

  > ${StyledVmsIconS} {
    color: #6a6a6a;
    margin-right: ${props => props.theme.gutter.standard};
  }
`

const Hr = styled.hr`
  border: 0;
  height: 0;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`

const OrderingSitesTableMenu = ({
  siteId,
  rowIndex,
  venueUrlKey,
  siteUrlKey,
  onDeleteClick,
  onSetThirdPartyDefault,
  thirdPartyDefaultOrderingSiteId,
}) => {
  const siteLink = `${window.location.origin}/explore/${venueUrlKey}/ordering/${siteUrlKey}`
  const isThirdPartyDefault = thirdPartyDefaultOrderingSiteId === siteId
  const setThirdPartyDefaultHandlerNewValue = isThirdPartyDefault ? null : siteId
  return (
    <MenuContainer>
      <Link
        className="dining-widget-settings-link"
        to={`/manager2/${venueUrlKey}/settings/ordering/site/${siteId}`}
        data-test={`ordering-site-edit-link-row-${rowIndex}`}
      >
        <MenuOption>
          <StyledVmsIconS>{VmsIcons.EditPencil}</StyledVmsIconS> Edit
        </MenuOption>
      </Link>
      <MenuOption onClick={copyToClipboard.bind(this, siteLink)} data-test={`ordering-site-copy-link-row-${rowIndex}`}>
        <StyledVmsIconS>{VmsIcons.LinkVariant}</StyledVmsIconS> Copy Link
      </MenuOption>
      <MenuOption onClick={onDeleteClick.bind(this, siteId)} data-test={`ordering-site-delete-link-row-${rowIndex}`}>
        <StyledVmsIconS>{VmsIcons.Delete}</StyledVmsIconS> Delete
      </MenuOption>
      <Hr />
      <MenuOption
        onClick={onSetThirdPartyDefault.bind(this, setThirdPartyDefaultHandlerNewValue)}
        data-test={`ordering-site-3rd-party-default-link-row-${rowIndex}`}
      >
        {isThirdPartyDefault ? 'Remove as 3rd party default' : 'Set as 3rd party default'}
      </MenuOption>
    </MenuContainer>
  )
}

export default OrderingSitesTableMenu
