import { useEffect, useCallback, useState } from 'react'
import { useInsightsDashboardContext } from '@sevenrooms/core/api'
import type { Insight } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Header } from '@sevenrooms/core/ui-kit/typography'
import { getHighlightHeadline, variantColor } from '../../utils'

export interface InsightHighlightRowProps {
  insight: Insight | null
  highlightInsights: Insight[]
}

export function InsightHighlightRow({ insight, highlightInsights }: InsightHighlightRowProps) {
  const { formatMessage } = useLocales()
  const { setSlideoutOpen, setBrowseStatus, setInsightList, setInsightId, insightId, setQueryId, queryId } = useInsightsDashboardContext()
  const [hoverId, setHoverId] = useState<string | null>(null)

  const openInsightDetail = useCallback(() => {
    setSlideoutOpen(true)
    setBrowseStatus(insight?.status || null)
    setInsightId(insight?.id || null)
    setInsightId(insight?.id || null)
    setInsightList(highlightInsights)
  }, [setSlideoutOpen, setBrowseStatus, setInsightList, setInsightId, insight, highlightInsights])

  useEffect(() => {
    if (queryId === insight?.id) {
      setQueryId(null)
      openInsightDetail()
    }
  }, [queryId, insight, openInsightDetail, setQueryId])

  const headline = getHighlightHeadline(insight?.insightTypeKey || '')
  const statusColor = variantColor[insight?.status || 'new']

  const stateColor = insightId === insight?.id || hoverId === insight?.id ? 'secondaryBackground' : 'primaryBackground'
  const backgroundColor = insight ? stateColor : 'dividerLines'

  return (
    <Box
      minHeight="100px"
      pl="lm"
      pt="l"
      pb="l"
      backgroundColor={backgroundColor}
      borderColor="dividerLines"
      borderRadius="s"
      cursor="pointer"
      onClick={openInsightDetail}
      onMouseEnter={() => setHoverId(insight?.id || null)}
      onMouseLeave={() => setHoverId(null)}
    >
      {insight && (
        <HStack alignItems="center" justifyContent="space-between">
          <Box p="s" mr="m" textAlign="center" borderRadius="l" height="33px" width="33px" backgroundColor={statusColor}>
            <Text fontWeight="bold" color="lightFont">
              {insight?.featuredRank}
            </Text>
          </Box>
          <VStack mr="auto">
            <Header mb="xs">{formatMessage(headline)}</Header>
            <Text>{insight?.insightText}</Text>
          </VStack>
          <Box pl="lm" pr="lm">
            <Icon name="VMSWeb-chevron-right" size="lg" color="darkFont" />
          </Box>
        </HStack>
      )}
    </Box>
  )
}
