import { toZonedTime } from 'date-fns-tz'
import { useCallback, useMemo } from 'react'
import type { AccessRule, AvailabilityDebuggerReasonsPayload } from '@sevenrooms/core/domain'
import { useLocation, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { cleanNullish } from '@sevenrooms/core/utils'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import {
  getSelectedAccessRuleOptions,
  transformAccessRuleDataToOptions,
  hasDurationOverride,
  filterAccessRuleDataByDate,
} from '../components/AvailabilityCalendarInput/AccessRuleHelpers'
import { useAvailabilityDebuggerContext } from '../contexts'

export interface AvailabilityUrlParams extends Omit<AvailabilityDebuggerReasonsPayload, 'venueId' | 'partySize' | 'time'> {}

export function useAvailabilityUrlParams() {
  const { search } = useLocation()
  const { updateQuery } = useNavigation()
  const { accessRules } = useAvailabilityDebuggerContext()

  const date = useDateParam()
  const params = useMemo(() => getParams(accessRules, date, search), [accessRules, date, search])

  const setParams = useCallback(
    (newParams: Partial<AvailabilityUrlParams>) => {
      const oldParams = getParams(accessRules, date, search)
      const urlParams = { ...oldParams, ...newParams }
      updateQuery(
        routes.manager2.availability,
        cleanNullish({
          ...urlParams,
          date: urlParams.date.toIso(),
        }),
        'replace'
      )
    },
    [accessRules, date, search, updateQuery]
  )
  return [params, setParams] as const
}

export function useDateParam() {
  const { search } = useLocation()
  const today = useVenueTodayDate()

  return useMemo(() => {
    const searchParams = new URLSearchParams(search)

    const dateParam = DateOnly.fromSafe(searchParams.get('date'))
    return dateParam != null && dateParam.isGreaterThanOrEqualTo(today) ? dateParam : today
  }, [search, today])
}

export function useVenueTodayDate() {
  const { venueTimezone } = useAppContext()
  const venueToday = toZonedTime(new Date(Date.now()), venueTimezone)

  return useMemo(() => DateOnly.fromDate(venueToday), [venueToday])
}

function getParams(accessRules: AccessRule[], date: DateOnly, search?: string) {
  const searchParams = new URLSearchParams(search)
  const durationOverrideParam = searchParams.get('durationOverride')
  const accessPersistentIds = searchParams.getAll('accessPersistentIds')

  const availableAccessRules = filterAccessRuleDataByDate(accessRules, date.toIso())
  const newOptions = transformAccessRuleDataToOptions(availableAccessRules)
  const updatedAccessPersistentIds = getSelectedAccessRuleOptions(newOptions, accessPersistentIds).map(ar => ar.value)

  const durationOverride =
    durationOverrideParam != null && hasDurationOverride(availableAccessRules) ? parseInt(durationOverrideParam) : null

  return cleanNullish({
    date,
    durationOverride,
    accessPersistentIds: updatedAccessPersistentIds,
    audience: searchParams.get('audience') ?? 'SEVENROOMS_WIDGET',
    seatingAreaIds: searchParams.getAll('seatingAreaIds'),
    shiftPersistentId: searchParams.get('shiftPersistentId'),
  })
}
