import { type FormEvent, useCallback } from 'react'
import type { OrderStatus, Order } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { SelectFilter, SelectTarget } from '@sevenrooms/core/ui-kit/core'
import { MultiSelect, SingleDatePicker, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, FiltersPanel } from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import { useNavigationCalendarContext } from '@sevenrooms/mgr-core/hooks/useNavigationCalendarContext'
import { useOrdersManagementContext } from '../hooks/useOrdersManagementContext'
import { useOrdersManagementResourcesContext } from '../hooks/useOrdersManagementResourcesContext'
import { ordersMessages } from '../locales/ordersMessages'
import { EmptyOrdersState } from './EmptyOrdersState'
import { OrdersFooter } from './OrdersFooter'
import { OrdersTable } from './OrdersTable'

interface OrderContentProps {
  venue: Venue
  orders: Order[]
  onSlideOutOrderStatusChange: (orderId: String, status: OrderStatus) => void
}

export function OrdersContent({ venue, orders, onSlideOutOrderStatusChange }: OrderContentProps) {
  const nav = useNavigation()
  const { formatMessage } = useLocales()
  const { date: NavDate, highlightedDates, setDate } = useNavigationCalendarContext()

  const onDateChange = useCallback(
    (date: Date | null) => {
      const dateString = date ? DateOnly.fromDate(date).toIso() : ''
      nav.replace(routes.manager2.orders, { params: { venueKey: venue?.urlKey }, query: { date: dateString } })
      setDate(date as Date)
    },
    [nav, setDate, venue?.urlKey]
  )

  const isDayHighlighted = useCallback(
    (day: Date) => {
      const formatted = DateOnly.fromDate(day).toIso()
      return highlightedDates.includes(formatted)
    },
    [highlightedDates]
  )

  const { statusChoices } = useOrdersManagementResourcesContext()

  const {
    filterSearchValue,
    setFilterSearchValue,
    selectedStatuses,
    setSelectedStatuses,
    filteredFulfillmentChoices,
    selectedFulfillment,
    setSelectedFulfillment,
    filteredOrders,
  } = useOrdersManagementContext(orders)

  return (
    <Box data-test="sr-orders-content">
      <Box pl="lm" pr="lm" height="100%">
        <OrdersTable
          orders={filteredOrders}
          venue={venue}
          onSlideOutOrderStatusChange={onSlideOutOrderStatusChange}
          placeholder={!filteredOrders.length ? <EmptyOrdersState orders={orders} filteredOrders={filteredOrders} /> : undefined}
          filterPanel={
            <FiltersPanel data-test="sr-orders-filter" justifyContent="space-between">
              <HStack spacing="m" flexWrap="wrap">
                <Label width="160px" primary={formatMessage(ordersMessages.ordersTableFilterDate)}>
                  <SingleDatePicker
                    value={NavDate}
                    onChange={onDateChange}
                    isDayHighlighted={isDayHighlighted}
                    isOutsideRange={() => false}
                    showClearDate={false}
                  />
                </Label>
                {!!orders.length && (
                  <>
                    <Label width="175px" primary={formatMessage(ordersMessages.ordersTableFilterStatus)}>
                      <MultiSelect
                        data-test="sr-orders-select-status"
                        placeholder={formatMessage(ordersMessages.ordersTableFilterStatusPlaceholder)}
                        options={statusChoices}
                        initialSelectedIds={selectedStatuses}
                        onChange={setSelectedStatuses}
                        usePills={false}
                        useCheckboxItems
                        showRemoveAllButton
                        displayAsTextWhenCollapsed
                        showMoreLabel=""
                        scrollLock
                      />
                    </Label>
                    <Label width="191px" primary={formatMessage(ordersMessages.ordersTableFilterFulfillment)}>
                      <MultiSelect
                        data-test="sr-orders-select-fulfillment"
                        placeholder={formatMessage(ordersMessages.ordersTableFilterFulfillmentPlaceholder)}
                        options={filteredFulfillmentChoices}
                        initialSelectedIds={selectedFulfillment}
                        onChange={setSelectedFulfillment}
                        usePills={false}
                        useCheckboxItems
                        showRemoveAllButton
                        displayAsTextWhenCollapsed
                        showMoreLabel=""
                        scrollLock
                      />
                    </Label>
                  </>
                )}
              </HStack>
              {!!orders.length && (
                <Label width="370px" primary="&nbsp;">
                  <SelectTarget data-test="sr-orders-search-bar">
                    <SelectFilter
                      placeholder={formatMessage(ordersMessages.ordersTableFilterBySearchStringPlaceholder)}
                      selectedValue={filterSearchValue || null}
                      searchable
                      onChange={(event: FormEvent<HTMLInputElement>) => {
                        setFilterSearchValue(event.currentTarget.value)
                      }}
                    />
                  </SelectTarget>
                </Label>
              )}
            </FiltersPanel>
          }
        />
      </Box>
      {!!orders.length && (
        <OrdersFooter
          data-test="sr-orders-footer"
          orders={orders}
          filteredOrders={filteredOrders}
          isFiltering={!!filterSearchValue || !!selectedFulfillment.length || !!selectedStatuses.length}
        />
      )}
    </Box>
  )
}
