import { defineMessages } from '@sevenrooms/core/locales'

export const reportingMessages = defineMessages({
  reportingTitle: {
    id: 'reporting.reportingTitle',
    defaultMessage: 'Reporting',
  },
  reportingDescription: {
    id: 'reporting.reportingDescription',
    defaultMessage: 'Explore and investigate live metrics about your operation',
  },
  reportingSearchPlaceholder: {
    id: 'reporting.reportingSearchPlaceholder',
    defaultMessage: 'Find a report',
  },
  reportingCategoryAdministrative: {
    id: 'reporting.reportingCategoryAdministrative',
    defaultMessage: 'Administrative',
  },
  reportingCategoryStandard: {
    id: 'reporting.reportingCategoryStandard',
    defaultMessage: 'Standard',
  },
  headerAllReports: {
    id: 'reporting.headerAllReports',
    defaultMessage: 'All Reports',
  },
  headerHighlightedReports: {
    id: 'reporting.headerAllReports',
    defaultMessage: 'Highlighted Reports',
  },
  headerSearchResults: {
    id: 'reporting.headerAllReports',
    defaultMessage: 'Search Results',
  },
  badgePopular: {
    id: 'reporting.badgePopular',
    defaultMessage: '{icon} Popular',
  },
  badgeRecentlyViewed: {
    id: 'reporting.badgeRecentlyViewed',
    defaultMessage: '{icon} Recently Viewed',
  },
})
