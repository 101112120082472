import { defineMessages } from '@sevenrooms/core/locales'

export const availabilityDebuggerMessages = defineMessages({
  title: {
    id: 'availabilityDebugger.title',
    defaultMessage: 'Availability Quick View',
  },
  subtitle: {
    id: 'availabilityDebugger.subtitle',
    defaultMessage:
      'Click on the desired time slot to learn more about Availability by Party Size, Seating Area, Audience or Access Rule. Click <a>here</a> to learn more.',
  },
  partySize: {
    id: 'availabilityDebugger.partySize',
    defaultMessage: '{partySize} Covers',
  },
  partySizeOne: {
    id: 'availabilityDebugger.partySize',
    defaultMessage: '1 Cover',
  },
  keyAvailable: {
    id: 'availabilityDebugger.keyAvailable',
    defaultMessage: 'Available',
  },
  keyNotAvailable: {
    id: 'availabilityDebugger.keyNotAvailable',
    defaultMessage: 'Not Available',
  },
  keyInactive: {
    id: 'availabilityDebugger.keyInactive',
    defaultMessage: 'Inactive',
  },
  descriptionInactive: {
    id: 'availabilityDebugger.descriptionInactive',
    defaultMessage: 'This timeslot is Inactive because the venue is either closed or the time is in the past',
  },
  dateLabel: {
    id: 'availabilityDebugger.dateLabel',
    defaultMessage: 'Date',
  },
  audienceLabel: {
    id: 'availabilityDebugger.audienceLabel',
    defaultMessage: 'Audience',
  },
  accessRulesLabel: {
    id: 'availabilityDebugger.accessRulesLabel',
    defaultMessage: 'Access Rules',
  },
  seatingAreaLabel: {
    id: 'availabilityDebugger.seatingAreaLabel',
    defaultMessage: 'Seating Area',
  },
  durationLabel: {
    id: 'availabilityDebugger.durationLabel',
    defaultMessage: 'Duration Picker',
  },
  durationTooltip: {
    id: 'availabilityDebugger.durationTooltip',
    defaultMessage: 'This is relevant for Access Rules using Duration Picker',
  },
  multiSelectPlaceholder: {
    id: 'availabilityDebugger.multiSelectPlaceholder',
    defaultMessage: 'All',
  },
  noShiftsForDay: {
    id: 'availabilityDebugger.noShiftsForDay',
    defaultMessage: 'There are no Shifts for the selected date',
  },
  totalBookedCovers: {
    id: 'availabilityDebugger.totalBookedCovers',
    defaultMessage: 'Total Booked Covers',
  },
} as const)
