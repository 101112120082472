/* eslint react/prop-types: 0 */
import { withRouter, Route, Switch, Redirect } from 'react-router'
import styled from 'styled-components'
import { ErrorNotFound } from 'mgr/pages/default'
import LandingPageSettings from 'mgr/pages/single-venue/marketing/containers/LandingPageSettings'
import {
  CustomSmsLanguageStringsContainer,
  WidgetSettingsContainer,
  SMSSettingsContainer,
  EmailSettingsContainer,
  PagesSettingsContainer,
  PoliciesSettingsContainer,
  POLICY_URL_KEYS,
} from 'mgr/pages/single-venue/settings/containers'
import BookingChannels from 'mgr/pages/single-venue/settings/containers/BookingChannels'
import EmailServiceProviders from 'mgr/pages/single-venue/settings/containers/EmailServiceProviders'
import LanguageSettings from 'mgr/pages/single-venue/settings/containers/LanguageSettings'
import BrandSettings from 'mgr/pages/single-venue/settings/containers/ordering/BrandSettings'
import OrderingCheckout from 'mgr/pages/single-venue/settings/containers/ordering/Checkout'
import CheckoutFee from 'mgr/pages/single-venue/settings/containers/ordering/CheckoutFee'
import DeliveryIntegrations from 'mgr/pages/single-venue/settings/containers/ordering/DeliveryIntegrations'
import EightySixProduct from 'mgr/pages/single-venue/settings/containers/ordering/EightySixProduct'
import Menu from 'mgr/pages/single-venue/settings/containers/ordering/MenuManagement/Menu'
import MenusList from 'mgr/pages/single-venue/settings/containers/ordering/MenuManagement/MenusList'
import PacingRuleEdit from 'mgr/pages/single-venue/settings/containers/ordering/OrderPacing/PacingRuleEdit'
import PacingRuleList from 'mgr/pages/single-venue/settings/containers/ordering/OrderPacing/PacingRuleList'
import ProductInventory from 'mgr/pages/single-venue/settings/containers/ordering/ProductInventory/ProductInventory'
import ProductItem from 'mgr/pages/single-venue/settings/containers/ordering/ProductInventory/ProductInventoryItem'
import EditSite from 'mgr/pages/single-venue/settings/containers/ordering/SiteManagement/EditSite'
import OrderingSites from 'mgr/pages/single-venue/settings/containers/ordering/SiteManagement/OrderingSites'
import PointOfSalesProviders from 'mgr/pages/single-venue/settings/containers/PointOfSales'
import PolicyLanguageStrings from 'mgr/pages/single-venue/settings/containers/PolicyLanguageStrings'
import ReservationAndClientImport from 'mgr/pages/single-venue/settings/containers/ReservationAndClientImport'
import ReviewChannels from 'mgr/pages/single-venue/settings/containers/ReviewChannels'
import LanguageStringSettings from 'mgr/pages/single-venue/settings/containers/shared/LanguageStringSettings'
import {
  RESERVATION_WIDGET,
  RESERVATION_CONFIRMATION_EMAIL,
  RESERVATION_UPDATE,
  RESERVATION_CANCELLATION,
  FEEDBACK_REQUEST_RESERVATION_EMAIL,
  FEEDBACK_REQUEST_ORDER_EMAIL,
  FEEDBACK_PAGE,
  RECEIPT_PAGE,
  PAYLINK_PAGE,
  PAYLINK_REQUEST_EMAIL,
  COMPLETED_TRANSACTION_EMAIL,
  SMS_RES,
  SMS_WAITLIST,
  RES_WIDGET_CANCEL_PAGE,
  RES_WIDGET_CONFIRM_PAGE,
  ORDERING_SITE,
  WAITLIST_WIDGET,
  RESERVATION_REMINDER_EMAIL,
  POLICY_TYPE_BOOKING,
  POLICY_TYPE_PAYMENT,
  OTHER_POLICIES,
  SMS_ORDER_MENU,
  SMS_ORDERING,
  REQUEST_EMAIL,
  GUESTLIST_EMAIL,
  EVENT_WIDGET,
  EVENT_CONFIRMATION_EMAIL,
  SMS_PRIORITY_ALERTS,
  SMS_PRIVATE_LINE,
  EMAILS_PRIORITY_ALERTS,
  SUBSCRIPTION_WIDGET,
  EMAIL_PREFERENCE_CENTER,
  SUBSCRIPTION_SUCCESS_PAGE,
  SUBSCRIPTION_CONFIRMATION_EMAIL,
  CONTINUED_MARKETING_SUBSCRIPTION_CONFIRMATION_EMAIL,
  SUBSCRIPTION_OPT_IN,
} from 'svr/common/LanguageConstants'
import { POSITypeEnum } from '@sevenrooms/core/domain'
import { OrderingNotificationSettings } from '@sevenrooms/mgr-ordering-notification-settings/OrderingNotificationSettings'
import { MiddlewareSource, PRODUCT_TYPES_DISPLAY_NAMES } from './constants'

const SettingsContainer = styled.div`
  display: flex;
  flex: 1;
`

const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
`

const POLICY_INFO_TEXT =
  'All policies will be shown in the policy dropdown in the Access Rules setting slideout. You can use HTML to enhance the appearance of the policy in the policy dropdown in the Access Rules setting slideout.'

const Settings = props => {
  const { match, venue, permissions, venueSettings } = props
  const canAccessOrdering = venue.isSevenRoomsOrderingEnabled && permissions.canAccessSettingsNav && permissions.canManageRestrictedSettings
  const canAccessIntegrations =
    permissions.canAccessSettingsNav && permissions.canManageRestrictedSettings && !permissions.isOnboardingSpecialist
  const accessRedirect = (canAccess, Page, props) => (canAccess ? <Page {...props} /> : <Redirect to={`${match.path}/venue`} />)
  return (
    <SettingsContainer>
      <ContentContainer>
        <Switch>
          {(permissions.canAccessSettingsNav ||
            permissions.canAdministrateShiftsAccessRules ||
            permissions.canAdministrateBlackoutDatesDailyProgram) && (
            <Switch>
              {canAccessIntegrations && permissions.canManageBookingChannels && (
                <Route path={`${match.path}/bookingchannels`} component={BookingChannels} />
              )}
              {canAccessIntegrations && <Route path={`${match.path}/emailserviceproviders`} component={EmailServiceProviders} />}
              {canAccessIntegrations && venueSettings.direct_review_channels_enabled && (
                <Route
                  path={`${match.path}/reviewchannels`}
                  render={props => accessRedirect(venueSettings.direct_review_channels_enabled, ReviewChannels, props)}
                />
              )}
              {canAccessIntegrations &&
                venue.posActivated &&
                (venue.posIntegrationType === POSITypeEnum.LIGHTSPEED || venue.posIntegrationType === POSITypeEnum.LIGHTSPEED_KOUNTA) && (
                  <Route path={`${match.path}/pointofsale`} component={PointOfSalesProviders} />
                )}
              <Route path={`${match.path}/pointofsale`} component={PointOfSalesProviders} />

              <Route path={`${match.path}/reservation-import`} render={() => <ReservationAndClientImport importType="reservation" />} />
              <Route path={`${match.path}/client-import`} render={() => <ReservationAndClientImport importType="client" />} />
              <Route exact path={`${match.path}/widget_settings`} render={props => <WidgetSettingsContainer {...props} />} />
              <Route exact path={`${match.path}/page_settings`} render={props => <PagesSettingsContainer {...props} />} />
              <Route
                path={`${match.path}/page_settings/feedback_page_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Feedback Page"
                    routes={[
                      {
                        name: 'Pages',
                        route: `${match.path}/page_settings`,
                      },
                      {
                        name: 'Feedback Page',
                        route: `${match.path}/page_settings/feedback_page_language`,
                      },
                    ]}
                    interfaceType={FEEDBACK_PAGE}
                  />
                )}
              />
              <Route
                path={`${match.path}/page_settings/marketing_subscription_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Marketing Subscription"
                    routes={[
                      {
                        name: 'Pages',
                        route: `${match.path}/page_settings`,
                      },
                      {
                        name: 'Marketing Subscription',
                        route: `${match.path}/page_settings/marketing_subscription_language`,
                      },
                    ]}
                    interfaceType={SUBSCRIPTION_SUCCESS_PAGE}
                  />
                )}
              />
              <Route
                path={`${match.path}/page_settings/receipt_page_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Receipt Page"
                    routes={[
                      {
                        name: 'Pages',
                        route: `${match.path}/page_settings`,
                      },
                      {
                        name: 'Receipt Page',
                        route: `${match.path}/page_settings/receipt_page_language`,
                      },
                    ]}
                    interfaceType={RECEIPT_PAGE}
                  />
                )}
              />
              <Route
                path={`${match.path}/page_settings/paylink_page_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Paylink Page"
                    routes={[
                      {
                        name: 'Pages',
                        route: `${match.path}/page_settings`,
                      },
                      {
                        name: 'Paylink Page',
                        route: `${match.path}/page_settings/paylink_page_language`,
                      },
                    ]}
                    interfaceType={PAYLINK_PAGE}
                  />
                )}
              />
              <Route
                path={`${match.path}/widget_settings/reservation_widget_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Reservation Widget Language Settings"
                    routes={[
                      {
                        name: 'Widgets',
                        route: `${match.path}/widget_settings`,
                      },
                      {
                        name: 'Reservation Widget Language Settings',
                        route: `${match.path}/widget_settings/reservation_widget_language`,
                      },
                    ]}
                    interfaceType={RESERVATION_WIDGET}
                  />
                )}
              />
              <Route
                path={`${match.path}/widget_settings/waitlist_widget_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Waitlist Widget Language Settings"
                    routes={[
                      {
                        name: 'Widgets',
                        route: `${match.path}/widget_settings`,
                      },
                      {
                        name: 'Waitlist Widget Language Settings',
                        route: `${match.path}/widget_settings/waitlist_widget_language`,
                      },
                    ]}
                    interfaceType={WAITLIST_WIDGET}
                  />
                )}
              />
              <Route
                path={`${match.path}/widget_settings/event_widget_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Event Widget Language Settings"
                    routes={[
                      {
                        name: 'Widgets',
                        route: `${match.path}/widget_settings`,
                      },
                      {
                        name: 'Events Widget Language Settings',
                        route: `${match.path}/widget_settings/event_widget_language`,
                      },
                    ]}
                    interfaceType={EVENT_WIDGET}
                  />
                )}
              />
              <Route
                path={`${match.path}/widget_settings/subscription_widget_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Subscription Widget Language Settings"
                    routes={[
                      {
                        name: 'Widgets',
                        route: `${match.path}/widget_settings`,
                      },
                      {
                        name: 'Subscriptions Widget Language Settings',
                        route: `${match.path}/widget_settings/subscription_widget_language`,
                      },
                    ]}
                    interfaceType={SUBSCRIPTION_WIDGET}
                  />
                )}
              />
              <Route
                exact
                path={`${match.path}/ordering/sites`}
                render={props => accessRedirect(canAccessOrdering, OrderingSites, props)}
              />
              <Route
                path={`${match.path}/page_settings/cancellation_page_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Cancellation Page"
                    routes={[
                      {
                        name: 'Pages',
                        route: `${match.path}/page_settings`,
                      },
                      {
                        name: 'Cancellation Page',
                        route: `${match.path}/page_settings/cancellation_page_language`,
                      },
                    ]}
                    interfaceType={RES_WIDGET_CANCEL_PAGE}
                  />
                )}
              />
              <Route
                path={`${match.path}/page_settings/confirmation_page_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Confirmation Page"
                    routes={[
                      {
                        name: 'Pages',
                        route: `${match.path}/page_settings`,
                      },
                      {
                        name: 'Confirmation Page',
                        route: `${match.path}/page_settings/confirmation_page_language`,
                      },
                    ]}
                    interfaceType={RES_WIDGET_CONFIRM_PAGE}
                  />
                )}
              />
              <Route
                path={`${match.path}/page_settings/email_preference_center_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Email Preference Center"
                    routes={[
                      {
                        name: 'Pages',
                        route: `${match.path}/page_settings`,
                      },
                      {
                        name: 'Email Preference Center',
                        route: `${match.path}/page_settings/email_preference_center_language`,
                      },
                    ]}
                    interfaceType={EMAIL_PREFERENCE_CENTER}
                  />
                )}
              />
              <Route
                path={`${match.path}/widget_settings/ordering_sites_language`}
                render={() => (
                  <LanguageStringSettings
                    title=" Ordering Sites"
                    routes={[
                      {
                        name: 'Widgets',
                        route: `${match.path}/widget_settings`,
                      },
                      {
                        name: ' Ordering Sites',
                        route: `${match.path}/widget_settings/ordering_sites_language`,
                      },
                    ]}
                    interfaceType={ORDERING_SITE}
                  />
                )}
              />
              <Route
                exact
                path={`${match.path}/ordering/sites`}
                render={props => accessRedirect(canAccessOrdering, OrderingSites, props)}
              />
              <Route
                exact
                path={`${match.path}/ordering/site/:id?`}
                render={props => {
                  const routes = [
                    {
                      name: 'Ordering Site Management',
                      route: `${match.path}/ordering/sites`,
                    },
                    {
                      name: 'Edit Ordering Site',
                      route: `${match.path}/ordering/site/:id?`,
                    },
                  ]
                  return accessRedirect(canAccessOrdering, EditSite, {
                    ...props,
                    routes,
                  })
                }}
              />
              <Route exact path={`${match.path}/ordering/menus`} render={props => accessRedirect(canAccessOrdering, MenusList, props)} />
              <Route
                exact
                path={`${match.path}/ordering/menu/:id?`}
                render={props => {
                  const isEdit = props.match.params.id
                  const canAccess = canAccessOrdering && (isEdit || venue.isSevenRoomsOrderingMiddleware)
                  const routes = [
                    {
                      name: 'Menu Management',
                      route: `${match.path}/ordering/menus`,
                    },
                    {
                      name: isEdit ? 'Edit Menu' : 'Create Menu',
                      route: `${match.path}/ordering/menu/:id?`,
                    },
                  ]
                  return accessRedirect(canAccess, Menu, {
                    ...props,
                    routes,
                  })
                }}
              />
              <Route
                exact
                path={`${match.path}/ordering/inventory/products`}
                render={props => accessRedirect(canAccessOrdering && venue.isSevenRoomsOrderingMiddleware, ProductInventory, props)}
              />
              <Route
                exact
                path={`${match.path}/ordering/inventory/product/:type?/:id?`}
                render={props => {
                  const isEdit = props.match.params.id
                  const isNoPosMiddlewareSource = venue.orderingMiddlewareSource === MiddlewareSource.NO_POS
                  const canAccess =
                    canAccessOrdering && ((isEdit && venue.isSevenRoomsOrderingMiddleware) || (!isEdit && isNoPosMiddlewareSource))
                  const routes = [
                    {
                      name: 'Product Inventory',
                      route: `${match.path}/ordering/inventory/products`,
                    },
                    {
                      name: `${isEdit ? 'Edit' : 'Create'} ${PRODUCT_TYPES_DISPLAY_NAMES[props.match.params.type.toUpperCase()]}`,
                      route: `${match.path}/ordering/inventory/product/:type?/:id?`,
                    },
                  ]
                  return accessRedirect(canAccess, ProductItem, {
                    ...props,
                    routes,
                  })
                }}
              />
              <Route
                exact
                path={`${match.path}/ordering/86product`}
                render={props => {
                  const routes = [
                    {
                      name: `86'd Products`,
                      route: `${match.path}/ordering/86product`,
                    },
                  ]
                  return accessRedirect(
                    !permissions.isOnboardingSpecialist && canAccessOrdering && venue.isSevenRoomsOrderingMiddleware,
                    EightySixProduct,
                    {
                      ...props,
                      routes,
                    }
                  )
                }}
              />
              <Route
                exact
                path={`${match.path}/ordering/checkout`}
                render={props => accessRedirect(!permissions.isOnboardingSpecialist && canAccessOrdering, OrderingCheckout, props)}
              />
              <Route
                exact
                path={`${match.path}/ordering/pacing-rules`}
                render={props => accessRedirect(canAccessOrdering, PacingRuleList, props)}
              />
              <Route
                exact
                path={`${match.path}/ordering/pacing-rules/:id`}
                render={props => {
                  const isCreate = props.match.params.id === 'new'
                  const routes = [
                    {
                      name: 'Order Pacing',
                      route: `${match.path}/ordering/pacing-rules`,
                    },
                    {
                      name: `${isCreate ? 'Create' : 'Edit'} Rule`,
                      route: `${match.path}/ordering/pacing-rules/:id`,
                    },
                  ]
                  return accessRedirect(canAccessOrdering, PacingRuleEdit, {
                    ...props,
                    routes,
                  })
                }}
              />
              <Route
                exact
                path={`${match.path}/ordering/service-fees`}
                render={props => accessRedirect(!permissions.isOnboardingSpecialist && canAccessOrdering, CheckoutFee, props)}
              />
              <Route
                exact
                path={`${match.path}/ordering/brand`}
                render={props => accessRedirect(canAccessOrdering, BrandSettings, props)}
              />
              <Route
                exact
                path={`${match.path}/ordering/notification_settings`}
                render={props => accessRedirect(canAccessOrdering && permissions.isOrderingSmsEnabled, OrderingNotificationSettings, props)}
              />
              <Route
                exact
                path={`${match.path}/ordering/delivery_integrations`}
                render={props => accessRedirect(!permissions.isOnboardingSpecialist && canAccessOrdering, DeliveryIntegrations, props)}
              />
              <Route exact path={`${match.path}/email_settings`} render={props => <EmailSettingsContainer {...props} />} />
              <Route
                path={`${match.path}/email_settings/reservation_confirmation`}
                render={() => (
                  <LanguageStringSettings
                    title="Reservation Email Confirmation"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Reservation Email Confirmation',
                        route: `${match.path}/email_settings/reservation_confirmation`,
                      },
                    ]}
                    interfaceType={RESERVATION_CONFIRMATION_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/reservation_update`}
                render={() => (
                  <LanguageStringSettings
                    title="Reservation Email Update"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Reservation Email Update',
                        route: `${match.path}/email_settings/reservation_update`,
                      },
                    ]}
                    interfaceType={RESERVATION_UPDATE}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/reservation_cancellation`}
                render={() => (
                  <LanguageStringSettings
                    title="Reservation Email Cancellation"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Reservation Email Cancellation',
                        route: `${match.path}/email_settings/reservation_cancellation`,
                      },
                    ]}
                    interfaceType={RESERVATION_CANCELLATION}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/feedback_request_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Visit Feedback Request Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Visit Feedback Request Email',
                        route: `${match.path}/email_settings/feedback_request_language`,
                      },
                    ]}
                    interfaceType={FEEDBACK_REQUEST_RESERVATION_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/order_feedback_request_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Order Feedback Request Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Order Feedback Request Email',
                        route: `${match.path}/email_settings/order_feedback_request_language`,
                      },
                    ]}
                    interfaceType={FEEDBACK_REQUEST_ORDER_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/paylink_request_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Paylink Request Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Paylink Request Email',
                        route: `${match.path}/email_settings/paylink_request_language`,
                      },
                    ]}
                    interfaceType={PAYLINK_REQUEST_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/reservation_reminder`}
                render={() => (
                  <LanguageStringSettings
                    title="Reservation Reminder"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Reservation Reminder',
                        route: `${match.path}/email_settings/reservation_reminder`,
                      },
                    ]}
                    interfaceType={RESERVATION_REMINDER_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/completed_transaction_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Completed Transaction Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Completed Transaction Email',
                        route: `${match.path}/email_settings/completed_transaction_language`,
                      },
                    ]}
                    interfaceType={COMPLETED_TRANSACTION_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/request_email`}
                render={() => (
                  <LanguageStringSettings
                    title="Request Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Request Email',
                        route: `${match.path}/email_settings/request_email`,
                      },
                    ]}
                    interfaceType={REQUEST_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/marketing_subscription_confirmation`}
                render={() => (
                  <LanguageStringSettings
                    title="Marketing Subscription Confirmation"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Marketing Subscription Confirmation',
                        route: `${match.path}/email_settings/marketing_subscription_confirmation`,
                      },
                    ]}
                    interfaceType={SUBSCRIPTION_CONFIRMATION_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/continued_marketing_subscription_confirmation_email`}
                render={() => (
                  <LanguageStringSettings
                    title="Continued Marketing Subscription Confirmation Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Continued Marketing Subscription Confirmation Email',
                        route: `${match.path}/email_settings/continued_marketing_subscription_confirmation_email`,
                      },
                    ]}
                    interfaceType={CONTINUED_MARKETING_SUBSCRIPTION_CONFIRMATION_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/subscription_opt_in`}
                render={() => (
                  <LanguageStringSettings
                    title="Subscription Opt In"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Subscription Opt In',
                        route: `${match.path}/email_settings/subscription_opt_in`,
                      },
                    ]}
                    interfaceType={SUBSCRIPTION_OPT_IN}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/guestlist_confirmation`}
                render={() => (
                  <LanguageStringSettings
                    title="Guestlist Confirmation Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Guestlist Confirmation Email',
                        route: `${match.path}/email_settings/guestlist_confirmation`,
                      },
                    ]}
                    interfaceType={GUESTLIST_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/event_confirmation`}
                render={() => (
                  <LanguageStringSettings
                    title="Event Confirmation Email"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Event Confirmation Email',
                        route: `${match.path}/email_settings/event_confirmation`,
                      },
                    ]}
                    interfaceType={EVENT_CONFIRMATION_EMAIL}
                  />
                )}
              />
              <Route
                path={`${match.path}/email_settings/emails_priority_alerts`}
                render={() => (
                  <LanguageStringSettings
                    title="Emails Priority Alerts"
                    routes={[
                      {
                        name: 'Emails',
                        route: `${match.path}/email_settings`,
                      },
                      {
                        name: 'Emails Priority Alerts',
                        route: `${match.path}/email_settings/emails_priority_alerts`,
                      },
                    ]}
                    interfaceType={EMAILS_PRIORITY_ALERTS}
                  />
                )}
              />
              <Route
                path={`${match.path}/sms_settings/sms_res_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Reservations"
                    routes={[
                      {
                        name: 'SMS',
                        route: `${match.path}/sms_settings`,
                      },
                      {
                        name: 'Reservations',
                        route: `${match.path}/sms_settings/sms_res_language`,
                      },
                    ]}
                    interfaceType={SMS_RES}
                  />
                )}
              />
              <Route
                path={`${match.path}/sms_settings/sms_waitlist_language`}
                render={() => (
                  <LanguageStringSettings
                    title="Waitlist"
                    routes={[
                      {
                        name: 'SMS',
                        route: `${match.path}/sms_settings`,
                      },
                      {
                        name: 'Waitlist',
                        route: `${match.path}/sms_settings/sms_waitlist_language`,
                      },
                    ]}
                    interfaceType={SMS_WAITLIST}
                  />
                )}
              />
              <Route
                path={`${match.path}/sms_settings/sms_order_menu_language`}
                component={() => (
                  <LanguageStringSettings
                    title="Seated Order Site Link"
                    routes={[
                      {
                        name: 'SMS',
                        route: `${match.path}/sms_settings`,
                      },
                      {
                        name: 'Seated Order Site Link',
                        route: `${match.path}/sms_settings/sms_order_menu_language`,
                      },
                    ]}
                    interfaceType={SMS_ORDER_MENU}
                  />
                )}
              />
              <Route
                path={`${match.path}/sms_settings/sms_ordering`}
                render={() => (
                  <LanguageStringSettings
                    title="Ordering"
                    routes={[
                      {
                        name: 'SMS',
                        route: `${match.path}/sms_settings`,
                      },
                      {
                        name: 'Ordering',
                        route: `${match.path}/sms_settings/sms_ordering`,
                      },
                    ]}
                    interfaceType={SMS_ORDERING}
                  />
                )}
              />
              <Route
                path={`${match.path}/sms_settings/custom_sms`}
                render={() => (
                  <CustomSmsLanguageStringsContainer
                    routes={[
                      {
                        name: 'SMS',
                        route: `${match.path}/sms_settings`,
                      },
                      {
                        name: 'Custom Messages',
                        route: `${match.path}/sms_settings/custom_sms`,
                      },
                    ]}
                  />
                )}
              />
              <Route
                path={`${match.path}/sms_settings/sms_priority_alerts`}
                render={() => (
                  <LanguageStringSettings
                    title="Priority Alerts"
                    routes={[
                      {
                        name: 'SMS',
                        route: `${match.path}/sms_settings`,
                      },
                      {
                        name: 'Priority Alerts',
                        route: `${match.path}/sms_settings/sms_priority_alerts`,
                      },
                    ]}
                    interfaceType={SMS_PRIORITY_ALERTS}
                  />
                )}
              />
              <Route
                path={`${match.path}/sms_settings/sms_private_line`}
                render={() => (
                  <LanguageStringSettings
                    title="Private Line"
                    routes={[
                      {
                        name: 'SMS',
                        route: `${match.path}/sms_settings`,
                      },
                      {
                        name: 'Private Line',
                        route: `${match.path}/sms_settings/sms_private_line`,
                      },
                    ]}
                    interfaceType={SMS_PRIVATE_LINE}
                  />
                )}
              />
              <Route
                path={`${match.path}/policy_settings/${POLICY_URL_KEYS.BOOKING}`}
                render={() => (
                  <PolicyLanguageStrings
                    title="Booking Policies"
                    routes={[
                      {
                        name: 'Policies',
                        route: `${match.path}/policy_settings`,
                      },
                      {
                        name: 'Booking Policies',
                        route: `${match.path}/policy_settings/${POLICY_URL_KEYS.BOOKING}`,
                      },
                    ]}
                    interfaceType={POLICY_TYPE_BOOKING}
                    infoText={POLICY_INFO_TEXT}
                  />
                )}
              />
              <Route
                path={`${match.path}/policy_settings/${POLICY_URL_KEYS.PAYMENT}`}
                render={() => (
                  <PolicyLanguageStrings
                    title="Cancellation Policies"
                    routes={[
                      {
                        name: 'Policies',
                        route: `${match.path}/policy_settings`,
                      },
                      {
                        name: 'Cancellation Policies',
                        route: `${match.path}/policy_settings/${POLICY_URL_KEYS.PAYMENT}`,
                      },
                    ]}
                    interfaceType={POLICY_TYPE_PAYMENT}
                    infoText={POLICY_INFO_TEXT}
                  />
                )}
              />
              <Route
                path={`${match.path}/policy_settings/${POLICY_URL_KEYS.OTHER}`}
                render={() => (
                  <LanguageStringSettings
                    title="Other Policies"
                    routes={[
                      {
                        name: 'Policies',
                        route: `${match.path}/policy_settings`,
                      },
                      {
                        name: 'Other Policies',
                        route: `${match.path}/policy_settings/${POLICY_URL_KEYS.OTHER}`,
                      },
                    ]}
                    interfaceType={OTHER_POLICIES}
                  />
                )}
              />
              <Route path={`${match.path}/sms_settings`} render={props => <SMSSettingsContainer {...props} />} />
              <Route path={`${match.path}/policy_settings`} render={props => <PoliciesSettingsContainer {...props} />} />
              <Route path={`${match.path}/language_settings`} render={props => <LanguageSettings {...props} />} />
              {permissions.canAdministrateWidgetSettings && permissions.isLandingPageEnabled && (
                <Route path={`${match.path}/landingpage`} render={() => <LandingPageSettings />} />
              )}
              <Route path="*" component={ErrorNotFound} />
            </Switch>
          )}
          <Route path="*" component={ErrorNotFound} />
        </Switch>
      </ContentContainer>
    </SettingsContainer>
  )
}

export default withRouter(Settings)
