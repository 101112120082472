import {
  useGetAccessRulesListQuery,
  useGetAudienceHierarchyQuery,
  useGetAvailabilityBookedCoversQuery,
  useGetAvailabilityCalendarQuery,
  useGetSeatingAreasTablesQueryNew,
} from '@sevenrooms/core/api'
import type { AvailabilityCalendarPayload } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { getDateFNSLocale } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPage, SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Link } from '@sevenrooms/react-components/components/Link'
import { LocalizationProvider } from '@sevenrooms/react-components/components/LocalizationProvider'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { AvailabilityCalendarGrid } from '../../components/AvailabilityCalendarGrid'
import { AvailabilityCalendarLoader } from '../../components/AvailabilityCalendarGrid/AvailabilityCalendarLoader'
import { AvailabilityCalendarInput } from '../../components/AvailabilityCalendarInput'
import { AvailabilityCovers } from '../../components/AvailabilityCovers'
import { AvailabilityDebuggerProvider } from '../../contexts'
import { useAvailabilityUrlParams, useDateParam } from '../../hooks'
import { availabilityDebuggerMessages } from '../../locales'

export function AvailabilityDebugger() {
  const { venueId } = useVenueContext()
  const { formatMessage } = useLocales()
  const { venueLocale } = useAppContext()
  const locale = getDateFNSLocale(venueLocale)

  const date = useDateParam()

  const accessRules = useGetAccessRulesListQuery({ venueId, startDate: date.toIso() })
  const audienceHierarchy = useGetAudienceHierarchyQuery({ venueId })
  const seatingAreasTables = useGetSeatingAreasTablesQueryNew({ venueId })
  const shiftCovers = useGetAvailabilityBookedCoversQuery({ venueId, date })

  const isFetching = audienceHierarchy.isFetching || accessRules.isFetching || seatingAreasTables.isFetching || shiftCovers.isFetching

  return (
    // This outer box with width=100% is necessary for the horizontal scroll inside the availability calendar grid.
    // SettingsPageLayout does not have width=100% unless you enable side navigation
    <Box width="100%" backgroundColor="primaryBackground">
      <LocalizationProvider adapterLocale={locale}>
        <SettingsPage showSideNavigation={false}>
          <SettingsPageMeta title={formatMessage(availabilityDebuggerMessages.title)} />
          <SettingsPageContent
            secondHeaderMaxWidth="70%"
            secondHeaderTextMaxWidth="100%"
            title={formatMessage(availabilityDebuggerMessages.title)}
            description={formatMessage(availabilityDebuggerMessages.subtitle, {
              a: (chunks: string[]) => (
                <Link href="https://help.sevenrooms.com/hc/en-us/articles/26560815768859/preview/eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MjY1NjA4MTU3Njg4NTksImV4cCI6MTcxODI5Mzk2NH0.WYr7Jvc7vakkGKPpWTIw8urwe0ms2tUT-ygoMWWT-j8">
                  {chunks}
                </Link>
              ),
            })}
            isShowingSideNavigation={false}
          >
            <Box backgroundColor="primaryBackground" width="100%" height="100%" p="l">
              {isFetching || accessRules.data == null || shiftCovers.data == null ? (
                <AvailabilityCalendarLoader minHeight={600} />
              ) : (
                <>
                  <AvailabilityDebuggerProvider value={{ accessRules: accessRules.data }}>
                    <AvailabilityDebuggerHydrated />
                  </AvailabilityDebuggerProvider>
                  <AvailabilityCovers shiftCovers={shiftCovers.data} />
                </>
              )}
            </Box>
          </SettingsPageContent>
        </SettingsPage>
      </LocalizationProvider>
    </Box>
  )
}

export function AvailabilityDebuggerHydrated() {
  const { venue } = useVenueContext()

  const [urlparams] = useAvailabilityUrlParams()

  const partySizes = [1, 2, 3, 4, 5, 6, 7, 8, 9]
  const query: AvailabilityCalendarPayload = {
    venueId: venue.id,
    partySizes,
    ...urlparams,
  }

  const minHeight = partySizes.length * 30 + 140

  const availabilityCalendarData = useGetAvailabilityCalendarQuery(query)

  if (!venue) {
    return null
  }

  return (
    <Stack direction="column" spacing={2}>
      <Box width="100%">
        <AvailabilityCalendarInput />
      </Box>
      <Box width="100%" height="100%">
        {availabilityCalendarData.isFetching || !availabilityCalendarData.data ? (
          <AvailabilityCalendarLoader minHeight={minHeight} />
        ) : (
          <AvailabilityCalendarGrid data={availabilityCalendarData.data} date={urlparams.date} />
        )}
      </Box>
    </Stack>
  )
}
