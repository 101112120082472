import { InputLabel, type InputLabelProps } from '@sevenrooms/react-components/components/InputLabel'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import type { PropsWithChildren } from 'react'

export interface StandardInputProps extends InputLabelProps {
  label: string
  width?: string | number
}

export function StandardInput({ children, htmlFor, label, width }: PropsWithChildren<StandardInputProps>) {
  const w = width ?? '220px'
  return children != null ? (
    <Stack direction="column" alignItems="start" width={w} spacing={0}>
      <InputLabel htmlFor={htmlFor}>{label}</InputLabel>
      {children}
    </Stack>
  ) : null
}
