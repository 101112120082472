import { Box } from '@sevenrooms/react-components/components/Box'
import { useTheme } from '@sevenrooms/react-components/hooks/useTheme'
import type { PropsWithChildren } from 'react'

export function AvailabilityCalendarContainer(props: PropsWithChildren<{ minHeight?: number }>) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: 1,
        borderColor: theme.palette.divider,
        p: theme.spacing(3),
        pb: 0,
        minHeight: props.minHeight,
      }}
    >
      {props.children}
    </Box>
  )
}
