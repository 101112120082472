import CloseIcon from '@mui/icons-material/Close'
import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { DialogTitle } from '@sevenrooms/react-components/components/DialogTitle'
import { IconButton } from '@sevenrooms/react-components/components/IconButton'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { reasonsModalMessages } from '../../locales/reasonsModalMessages'

export interface ReasonsModalTitleProps {
  onClose: Function
  date?: DateOnly
  time?: TimeOnly
  covers?: number
}

export function ReasonsModalTitle({ onClose, date, time, covers }: ReasonsModalTitleProps) {
  const { formatMessage } = useLocales()
  return (
    <DialogTitle id="reasons-modal-title" data-test="reasons-modal-title">
      <Stack direction="row" divider={<Typography variant="h2">•</Typography>} spacing={1}>
        <Typography id="title-date" variant="h2">
          {`${date?.formatNYearSMonthNDay()}`}
        </Typography>
        <Typography id="title-cover-info" variant="h2">
          {formatMessage(reasonsModalMessages.titleDate, { count: covers, covers, time: time?.formatSTime() })}
        </Typography>
      </Stack>
      <IconButton
        size="small"
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          position: 'absolute',
          right: 4,
          top: 4,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </DialogTitle>
  )
}
