import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { insightsMessages } from '../../../locales'
import { formatBold } from '../../../utils'

export function NoTableCombinationSuggestedActions() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const nav = useNavigation()

  return (
    <VStack p="s s s xxs" spacing="s" data-test="insights-no-seating-area-suggested-actions">
      <Text data-test="insights-no-table-combinations-suggested-actions-navigate">
        1.{' '}
        {formatMessage(insightsMessages.suggestedActionsTableCombinationNavigateStep, {
          icon: <Icon name="VMSWeb-open-in-new" size="sm" />,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: the type ParseMessage needs to be updated
          link: (chunks: string[]) => (
            <Link
              to={nav.href(routes.manager.manage.tableCombinations, {
                params: { venueKey: venue.urlKey },
              })}
              target="_blank"
            >
              {chunks}
            </Link>
          ),
        })}
      </Text>
      <Text data-test="insights-no-table-combinations-suggested-actions-new">
        2.{' '}
        {formatMessage(insightsMessages.suggestedActionsAddCombinationStep, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <Text data-test="insights-no-table-combinations-suggested-actions-add">
        3.{' '}
        {formatMessage(insightsMessages.suggestedActionsFillAndAddStep, {
          strong: (chunks: string[]) => formatBold(chunks),
        })}
      </Text>
      <Text data-test="insights-no-table-combinations-suggested-actions-repeat">
        4. {formatMessage(insightsMessages.suggestedActionsRepeatStep)}
      </Text>
    </VStack>
  )
}
