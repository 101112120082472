import { useGetAccessRulesListQuery, useGetAudienceHierarchyQuery, useGetSeatingAreasTablesQueryNew } from '@sevenrooms/core/api'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { useAvailabilityUrlParams } from '../../hooks'
import { AccessRuleInput } from './AccessRuleInput'
import { AudienceInput } from './AudienceInput'
import { DateInput } from './DateInput'
import { DurationInput } from './DurationInput'
import { SeatingAreaInput } from './SeatingAreaInput'

export function AvailabilityCalendarInput() {
  const { venueId } = useVenueContext()
  const [{ date }] = useAvailabilityUrlParams()

  const audienceHierarchy = useGetAudienceHierarchyQuery({ venueId })
  const accessRules = useGetAccessRulesListQuery({ venueId, startDate: date.toIso() })
  const seatingAreasTables = useGetSeatingAreasTablesQueryNew({ venueId })

  return (
    <Stack direction="column" spacing={0}>
      <Stack direction="row" alignItems="end" spacing={2} sx={{ width: '100%' }}>
        <DateInput />
        <AudienceInput audiences={audienceHierarchy.data} />
        <AccessRuleInput accessRules={accessRules.data} />
        <SeatingAreaInput seatingAreas={seatingAreasTables.data?.newSeatingAreaCodesToTables} />
        <DurationInput accessRules={accessRules.data} />
      </Stack>
    </Stack>
  )
}
