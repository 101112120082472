import React, { useState } from 'react'
import type { AvailabilityDebuggerReason, Venue } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { type DateOnly, TimeOnly, getHoursAndMinutes } from '@sevenrooms/core/timepiece'
import { AccordionDetails } from '@sevenrooms/react-components/components/AccordionDetails'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Link } from '@sevenrooms/react-components/components/Link'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesMatchCategoryMessages, accessRulesTargetingCategoryMessages } from '../../../locales'
import { groupReasonsByAccessRule } from './accessRuleCategoryUtil'
import { AccordionContent } from './AccordionContent'
import { getAccessRuleUrl } from './urlRedirectUtil'

interface AccessRulesTargetingCategoryProps {
  reasons: AvailabilityDebuggerReason[]
  defaultExpanded?: boolean
  date: DateOnly
  time: TimeOnly
  venue: Venue
  covers: number
  duration: number
}

interface AccessRulesTargetingDetailsProps {
  reasons: AvailabilityDebuggerReason[]
  date: DateOnly
  time: TimeOnly
  venue: Venue
  covers: number
  duration: number
}

function ARContent({ formattedHeader, formattedDescription }: { formattedHeader: string; formattedDescription: string }) {
  return (
    <Box sx={{ '&:not(:last-child)': { pb: 2 } }}>
      <Typography fontWeight="medium">{formattedHeader}</Typography>
      <Typography sx={{ pl: 1, listStyle: 'initial' }} component="li">
        {formattedDescription}
      </Typography>
    </Box>
  )
}

function AccessRulesTargetingDetails({ reasons, date, time, venue, covers, duration }: AccessRulesTargetingDetailsProps) {
  const { formatMessage } = useLocales()
  const reason = reasons[0]
  if (!reason) {
    return <></>
  }

  const formattedHeader = formatMessage(accessRulesMatchCategoryMessages.mainDescriptionHeader, {
    ruleName: reason.data?.accessRuleName,
    a: (chunks: string[]) =>
      reason.data?.accessRuleId ? (
        <Link href={getAccessRuleUrl(venue, date, reason.data?.accessRuleId)} target="_blank">
          {chunks}
        </Link>
      ) : (
        <>{chunks}</>
      ),
  })

  switch (reason.reason) {
    case 'PARTY_SIZE_RESTRICTION_MISMATCH': {
      const partyMin = reason.data?.isPartySizeRule ? reason.data.rulePartyMin : reason.data?.shiftPartyMin
      const partyMax = reason.data?.isPartySizeRule ? reason.data.rulePartyMax : reason.data?.shiftPartyMax
      return (
        <ARContent
          formattedHeader={formattedHeader}
          formattedDescription={formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionPartySizeRestrictionMismatch, {
            partySize: covers,
            partyMin,
            partyMax,
          })}
        />
      )
    }
    case 'AUDIENCE_TIER_MISMATCH':
      return (
        <ARContent
          formattedHeader={formattedHeader}
          formattedDescription={formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionAudienceTierMismatch)}
        />
      )
    case 'DURATION_RESTRICTION_MISMATCH': {
      const durationHoursAndMinutes = getHoursAndMinutes(duration)
      const durationMin = getHoursAndMinutes(reason.data?.durationMin ?? 0)
      const durationMax = getHoursAndMinutes(reason.data?.durationMax ?? 0)
      return (
        <ARContent
          formattedHeader={formattedHeader}
          formattedDescription={formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionDurationRestrictionMismatch, {
            durationHours: durationHoursAndMinutes.hours,
            durationMinutes: durationHoursAndMinutes.minutes,
            durationMinHours: durationMin.hours,
            durationMinMinutes: durationMin.minutes,
            durationMaxHours: durationMax.hours,
            durationMaxMinutes: durationMax.minutes,
          })}
        />
      )
    }
    case 'NOT_ALLOWED_TIME':
      return (
        <ARContent
          formattedHeader={formattedHeader}
          formattedDescription={formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionNotAllowedTime, {
            count: reason.data?.accessRuleSpecificTimes?.length,
            time: time.formatSTime(),
            arTimes: reason.data?.accessRuleSpecificTimes?.map(time => TimeOnly.fromSafe(time)?.formatSTime()).join(', '),
          })}
        />
      )
    case 'NOT_WITHIN_TIME_RANGE':
      return (
        <ARContent
          formattedHeader={formattedHeader}
          formattedDescription={formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionNotWithinTimeRange, {
            time: time.formatSTime(),
            arTimeRangeStart: TimeOnly.fromSafe(reason.data?.timeRangeStart)?.formatSTime(),
            arTimeRangeEnd: TimeOnly.fromSafe(reason.data?.timeRangeEnd)?.formatSTime(),
          })}
        />
      )
    case 'NOT_DURATION_PICKER_ELIGIBLE':
      return (
        <ARContent
          formattedHeader={formattedHeader}
          formattedDescription={formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionNotDurationPickerEligible, {
            ...getHoursAndMinutes(duration),
          })}
        />
      )
    case 'ONLY_DURATION_PICKER_ELIGIBLE':
      return (
        <ARContent
          formattedHeader={formattedHeader}
          formattedDescription={formatMessage(accessRulesTargetingCategoryMessages.mainDescriptionOnlyDurationPickerEligible)}
        />
      )
    default:
      return <></>
  }
}

export function AccessRulesTargetingCategory({
  reasons,
  defaultExpanded = true,
  covers,
  date,
  time,
  venue,
  duration,
}: AccessRulesTargetingCategoryProps) {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  const { formatMessage } = useLocales()

  const groupedReasons = groupReasonsByAccessRule(reasons)
  const headerMessage = formatMessage(accessRulesTargetingCategoryMessages.accordianHeader, {
    count: Object.keys(groupedReasons).length,
    numArs: Object.keys(groupedReasons).length,
  })

  return (
    <AccordionContent
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={handleChange}
      headerMessage={headerMessage}
      dataTestId="access-rules-targeting-category-content"
      actionButtonEnabled={false}
    >
      <AccordionDetails id="access-rules-targeting-panel-content">
        {groupedReasons.map(({ accessRuleId, reasonCode, reasons }) => (
          <AccessRulesTargetingDetails
            key={`${accessRuleId}-${reasonCode}`}
            reasons={reasons}
            date={date}
            time={time}
            venue={venue}
            covers={covers}
            duration={duration}
          />
        ))}
      </AccordionDetails>
    </AccordionContent>
  )
}
