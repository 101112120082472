import _ from 'lodash'
import { type ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { useSendNotificationMutation, useSendOrderRefundMutation, OrderService, type GetOrderResponse } from '@sevenrooms/core/api'
import type { OrderStatus, Transaction, Order } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import {
  Box,
  notify,
  Spreadsheet,
  BasicCell,
  basicCellSortFn,
  Window,
  type Row,
  type DataTableColumn,
  type SortingRule,
  type DataTableInstanceProps,
} from '@sevenrooms/core/ui-kit/layout'
import { useVenueSettingsContext, type Venue } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { ordersMessages } from '../locales/ordersMessages'
import { OrdersFormatService } from '../services'
import { OrderStatusCell } from './OrderStatusCell'
import { RefundOrderModal } from './RefundOrderModal'
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query'

const StatusWeight: Record<OrderStatus, number> = {
  QUEUED: 8,
  FAILED_INJECTION: 7,
  OPEN: 6,
  WORKING_ON_ORDER: 5,
  READY_PICKUP: 4,
  READY_DRIVER: 3,
  WITH_DRIVER: 2,
  CLOSED: 1,
  VOID: 0,
}

interface OrderTableProps {
  venue: Venue
  orders: Order[]
  onSlideOutOrderStatusChange: (orderId: String, status: OrderStatus) => void
  filterPanel?: ReactNode
  placeholder?: ReactNode
}

export function OrdersTable({ venue, orders, onSlideOutOrderStatusChange, placeholder, filterPanel }: OrderTableProps) {
  const nav = useNavigation()
  const { venueCurrencyCode } = useAppContext()
  const { venueSettings } = useVenueSettingsContext()
  const tableRef = useRef<DataTableInstanceProps>(null)
  const { formatMessage } = useLocales()
  const [sorted, onSortedChange] = useState<SortingRule<string>[]>()
  const [transactionToRefund, setTransactionToRefund] = useState<Transaction>()
  const [resources, setResources] = useState<GetOrderResponse>()

  const [sendOrderRefund] = useSendOrderRefundMutation()
  const [sendNotification] = useSendNotificationMutation()

  const onOrderStatusChange = useCallback(
    (order: Order, status: OrderStatus) => {
      if (status === 'VOID') {
        OrderService.getOrder(venue.id, order.id).then((resources: GetOrderResponse) => {
          const transactionToRefund = _.find(
            resources.transactions,
            (transaction: Transaction) =>
              !transaction.isRefund && transaction.amountRemainingDecimal && transaction.amountRemainingDecimal > 0
          ) as Transaction | undefined
          if (transactionToRefund) {
            setTransactionToRefund(transactionToRefund)
            setResources(resources)
            nav.push(routes.manager2.orders.refundModal, { params: { venueKey: venue.urlKey } })
          }
        })
      }
    },
    [nav, venue]
  )

  const refundOrder = useCallback(async () => {
    if (!resources || !transactionToRefund) {
      return
    }
    const refundAmount = transactionToRefund.amountRemainingDecimal
    const refundAmountInCents = refundAmount ? Math.round(refundAmount * 100) : 0
    const reason = ''
    const clientId = resources.venueGroupClientProfile.id

    const { data: transaction } = (await sendOrderRefund({
      venueUrlKey: venue.urlKey,
      amount: refundAmountInCents,
      historyId: transactionToRefund.id,
      clientId,
      reason,
    })) as { data?: Transaction }
    if (transaction) {
      notify({
        content: formatMessage(ordersMessages.ordersNotificationRefundSuccess),
        type: 'success',
      })
      const { error: notificationError } = (await sendNotification({
        order: resources.order,
        transaction,
        venueGroupClient: resources.venueGroupClientProfile,
      })) as { error: FetchBaseQueryError }
      if (notificationError) {
        notify({ content: formatMessage(ordersMessages.ordersNotificationFailure), type: 'error' })
      } else {
        notify({
          content: formatMessage(ordersMessages.ordersNotificationSuccess),
          type: 'success',
        })
      }
    } else {
      notify({ content: formatMessage(ordersMessages.ordersNotificationRefundFailure), type: 'error' })
    }
  }, [formatMessage, resources, sendNotification, sendOrderRefund, transactionToRefund, venue.urlKey])

  useMemo(() => {
    if (orders.length) {
      onSortedChange([{ id: 'readyTime', desc: true }])
    } else {
      onSortedChange([])
    }
  }, [orders])

  const showSlideOut = useCallback(
    (order: Order) => {
      window.SvrManager.OrderSlideout.viewOrder(venue.id, order.id, onSlideOutOrderStatusChange, () => {
        tableRef?.current?.deselectAll()
      })
    },
    [onSlideOutOrderStatusChange, venue.id]
  )

  const columns = useMemo<DataTableColumn<Order>[]>(
    () => [
      {
        header: formatMessage(ordersMessages.ordersTableColumnNameStatus),
        key: 'status',
        render: (order: Order) => (
          <OrderStatusCell
            venue={venue}
            order={order}
            onStatusChange={onOrderStatusChange}
            onOpen={() => {
              window.SvrManager.OrderSlideout.closeSlideout()
            }}
          />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        cellAlign: 'center',
        width: 83,
        maxWidth: 83,
        sortable: true,
        sortType: (rowA: Row<Order>, rowB: Row<Order>) =>
          StatusWeight[rowA.original.status] > StatusWeight[rowB.original.status] ? 1 : -1,
      },
      {
        header: formatMessage(ordersMessages.ordersTableColumnNameName),
        key: 'name',
        render: (order: Order, index: number) => (
          <BasicCell
            primary={FormatService.formatFullName(order.firstName, order.lastName, venueSettings?.reservation_display_order)}
            secondary={order.phoneNumberFormatted}
            data-test={`order-${index}-name`}
          />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        width: 220,
        maxWidth: 220,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(ordersMessages.ordersTableColumnNameReadyTime),
        key: 'readyTime',
        render: (order: Order, index: number) => (
          <BasicCell
            primary={(order.targetReadyDatetime || order.desiredDateTime || order.openedDateTime).toTimeOnly().formatSTime()}
            data-test={`order-${index}-ready-time`}
          />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        width: 120,
        maxWidth: 120,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(ordersMessages.ordersTableColumnNameOrderNumber),
        key: 'orderNumber',
        render: (order: Order, index: number) => <BasicCell primary={order.orderNumber} data-test={`order-${index}-order-number`} />,
        textDisplay: 'flex',
        headerAlign: 'start',
        width: 165,
        maxWidth: 165,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(ordersMessages.ordersTableColumnNameFulfillment),
        key: 'fulfillment',
        render: (order: Order, index: number) => (
          <BasicCell primary={OrdersFormatService.formatFulfillment(order)} secondary="" data-test={`order-${index}-fulfillment`} />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
        width: 140,
        maxWidth: 140,
        sortable: true,
        sortType: basicCellSortFn,
      },
      {
        header: formatMessage(ordersMessages.ordersTableColumnNameTotal),
        key: 'total',
        render: (order: Order, index: number) => (
          <BasicCell primary={FormatService.formatCurrency(order.total, venueCurrencyCode)} data-test={`order-${index}-total`} />
        ),
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
        width: 100,
        maxWidth: 100,
        sortable: true,
        sortType: (rowA: Row<Order>, rowB: Row<Order>) => (rowA.original.total > rowB.original.total ? 1 : -1),
      },
      {
        header: formatMessage(ordersMessages.ordersTableColumnNameNotes),
        key: 'specialInstructions',
        render: (order: Order, index: number) => (
          <BasicCell primary={order.specialInstructions} data-test={`order-${index}-specialInstructions`} />
        ),
        textDisplay: 'flex',
        headerAlign: 'start',
      },
    ],
    [formatMessage, onOrderStatusChange, venue, venueCurrencyCode, venueSettings?.reservation_display_order]
  )

  return (
    <Box data-test="sr-orders-table">
      <Spreadsheet
        ref={tableRef}
        data={orders}
        columns={columns}
        disableSortBy={!orders.length}
        sorted={sorted}
        onSortedChange={onSortedChange}
        onRowClick={showSlideOut}
        noPagination
        hoverOnRows
        filterPanel={filterPanel}
        placeholder={placeholder}
      />
      <Surface destination={routes.manager2.orders.refundModal}>
        <Window>
          <RefundOrderModal
            order={resources?.order}
            closeHref={nav.closeSurfaceHref(routes.manager2.orders.refundModal, {
              params: { venueKey: venue.urlKey },
            })}
            onConfirm={refundOrder}
          />
        </Window>
      </Surface>
    </Box>
  )
}
