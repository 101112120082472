import { useMemo } from 'react'
import { useInsightsDashboardContext } from '@sevenrooms/core/api'
import type { Insight, InsightVenueUser } from '@sevenrooms/core/domain'
import { DividerLine, HStack } from '@sevenrooms/core/ui-kit/layout'
import { AssignedUsers } from './AssignedUsers/AssignedUsers'
import { InsightFollowers } from './InsightFollowers/InsightFollowers'

export function InsightUsers({ insight }: { insight: Insight }) {
  const { insightVenueUsers } = useInsightsDashboardContext()

  const userOptions = useMemo(
    () =>
      insightVenueUsers
        ? insightVenueUsers.map((user: InsightVenueUser) => ({
            label: user.name,
            id: user.id,
          }))
        : [],
    [insightVenueUsers]
  )

  return (
    <>
      <DividerLine mt="lm" mb="lm" />
      <HStack spacing="m" pl="sm">
        <AssignedUsers insightId={insight.id} insightAssignedUserId={insight.assignedUserId} userOptions={userOptions} />
        <InsightFollowers insightId={insight.id} insightFollowerUserIds={insight.followerUserIds} userOptions={userOptions} />
      </HStack>
    </>
  )
}
